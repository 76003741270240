import { Component } from "@angular/core";
import { MyProfileAbstractComponent } from "../../my-profile-abstract.component";
import { Router } from "@angular/router";
import { NavigationService, OutletsRegistry, RaoutesRegistry } from "src/app/services/NavigationService.service";
import { Db3DApiBackendClient } from "src/app/services/api/db3d-api-backend-client.service";
import { WhiteLabelConfigurationService } from "src/app/services/white-labels/white-label-configuration.service";
import { UserDetailsService } from "src/app/services/user-details.service";

@Component({
  selector: "app-profile-picture-display",
  templateUrl: "./profile-picture-display.component.html",
  styleUrls: ["../profile-picture.component.scss", "../../my-profile-subcomponents.component.scss"]
})

export class ProfilePictureDisplayComponent extends MyProfileAbstractComponent {

  readonly defaultAvatarIcon: string = "assets/images/icons/avatar-icon.svg";

  constructor(
    db3DApiBackendClient: Db3DApiBackendClient,
    wlConfigService: WhiteLabelConfigurationService,
    userDetailsService: UserDetailsService,
    private navigationService: NavigationService,
    private router: Router,
  ) {
    super(db3DApiBackendClient, wlConfigService, userDetailsService);
  }

  editProfilePicture(): void {
    this.router.navigate([this.navigationService.myProfile, { outlets: { [OutletsRegistry.profilePicture]: [RaoutesRegistry.profilePictureEdit] } }]);
  }
}