import { AfterViewChecked, Component, OnInit } from "@angular/core";
import { UserService } from "src/app/services/authentication/user.service";
import { WhiteLabelConfigurationService } from "src/app/services/white-labels/white-label-configuration.service";
import { WhiteLabelContextService } from "src/app/services/white-labels/white-label-context.service";

@Component({
  selector: "app-pdf-template",
  templateUrl: "./pdf-template.component.html",
  styleUrls: ["./pdf-template.component.scss"]
})

export class PdfTemplateComponent implements OnInit, AfterViewChecked {

  constructor(
    private whiteLabelContextService: WhiteLabelContextService,
    private whiteLabelConfigurationService: WhiteLabelConfigurationService,
    private wlConfigService: WhiteLabelConfigurationService,
    private userService: UserService,
  ) {}

  public inputPDFTemplateValue: string;
  private whiteLabelSlug: string;
  public visiblePDFTab: boolean = false;
  public errorPdfTemplateMsg: string= "";

  ngOnInit(): void {
    this.whiteLabelSlug = this.whiteLabelContextService.getCurrentWhiteLabelSlug();
    this.whiteLabelConfigurationService.whiteLabelSetupConfiguration.subscribe((template) => {
      this.inputPDFTemplateValue = template.pdfTemplate;
    });
  }

  ngAfterViewChecked() {
    this.visiblePDFTab = this.userService.isWhiteLabelAdmin();
  }

  public updatePDFTemplate(): void {
    this.whiteLabelConfigurationService.updatePDFTemplate(this.whiteLabelSlug, this.inputPDFTemplateValue);
    this.wlConfigService.pdfTemplateErrorMsg.subscribe((value) => {
      this.errorPdfTemplateMsg = value;
    });
  }
  
}
