import { Component, Input} from '@angular/core';

@Component({
  selector: 'tab-switch',
  template: `<div [ngClass]="{'hideSwitchTab': !active}"><ng-content></ng-content></div>`,
  styleUrls: ['./switch-views-tabs.component.scss']
})
export class TabSwitchComponent {
  public active:boolean = false;

  @Input() public tabId: string;

  @Input() public label: string;
  @Input() set default(_:any){
    this.active = true;
  }
  @Input() activeTab: boolean;
}
