import { Component, Input } from "@angular/core";

@Component({
  selector: "app-my-profile-button",
  templateUrl: "./my-profile-button.component.html",
  styleUrls: ["./my-profile-button.component.scss"]
})

export class MyProfileButtonComponent {
  @Input() text: string;
  @Input() darkStyle?: boolean = false;
  @Input() disabled?: boolean = false;
}
