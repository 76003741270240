import { Component, OnInit, DoCheck, ViewChildren, QueryList, ElementRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { debounceTime, BehaviorSubject } from "rxjs";
import { Db3DApiBackendClient, QueryParamsForGetUsers } from "src/app/services/api/db3d-api-backend-client.service";
import { environment } from "src/environments/environment";
import { UsersRoleFactory, IUsersRoleApiResponse } from "src/app/factories/user-role-factory";
import { UsersRole } from "src/app/data-models/manage-users/user-role.model";
import { UserDetails } from "src/app/data-models/manage-users/user-details.model";
import { UsersDetailsFactory, IUserDetailsApiResponse } from "src/app/factories/user-details-factory";
import { UsersDataFetchMessages } from "src/app/data-models/manage-users/users-data-fetch-messages";
import { UsersPaginationFactory } from "src/app/factories/users-pagination-factory";
import { UsersPagination } from "src/app/data-models/manage-users/users-pagination.model";
import { NavigationService } from "src/app/services/NavigationService.service";
import { WhiteLabelConfigurationService } from "src/app/services/white-labels/white-label-configuration.service";

enum queryParamsKey {
  page = "page",
  search = "search",
  sort = "ordering",
}

enum sortDirection {
  asc = "ascending",
  desc = "descending",
  ascPrefix = "",
  descPrefix = "-",
}

export type SortDirection = "ascending" | "descending" | null;

@Component({
  selector: "app-users-list",
  templateUrl: "./users-list.component.html",
  styleUrls: ["./users-list.component.scss"]
})

export class UsersListComponent implements OnInit, DoCheck {

  public environment = environment;
  private readonly usersQuerySearchDelay: number = 250;
  private readonly debounceTimeSendRequest: number = 250;
  public users: Array<UserDetails>;
  public usersRoleList: UsersRole[];
  public searchInputValue = new BehaviorSubject<string>("");
  private usersRoleFactory: UsersRoleFactory = new UsersRoleFactory();
  private usersDetailsFactory: UsersDetailsFactory = new UsersDetailsFactory();
  public pagination: UsersPagination;
  public usersPaginationFactory: UsersPaginationFactory = new UsersPaginationFactory();
  public usersFetchingInProgress: boolean;
  public serverError: boolean;
  public noSearchingResults: boolean;
  public loadingMsg: string =  UsersDataFetchMessages.loading;
  public errorMsg: string = UsersDataFetchMessages.error;
  public emptyListMsg: string = UsersDataFetchMessages.emptyList;
  private lastSearchQuery: string = "";
  public static sortActiveClassName= "sort-active";
  public readonly iconPath = "assets/images/icons/arrow-down.png";
  public readonly tableHeadersParameters= [
    {
      name: "Email",
      orderingKey: "email"
    },
    {
      name: "Name",
      orderingKey: "full_name"
    },
    {
      name: "Joined",
      orderingKey: "date_joined"
    },
    {
      name: "Role",
      orderingKey: "role__name"
    },
  ];
  private currentOrdering: {[key: string]: SortDirection} = {};
  public wlSlug: string;

  @ViewChildren("sortingArrow") arrows: QueryList<ElementRef<any>>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private db3DApiBackendClient: Db3DApiBackendClient,
    public navigationService: NavigationService,
    private whiteLabelConfigurationService: WhiteLabelConfigurationService,
  ) {}

  ngOnInit(): void {
    this.whiteLabelConfigurationService.whiteLabelSetupConfiguration.subscribe({
      next: (config) => {
        this.wlSlug = config.slug;
        if(this.wlSlug) this.getUsersRoleList();
      },
    });
    const queryParamsFromUrl: QueryParamsForGetUsers = {
      page: this.route.snapshot.queryParamMap.get(queryParamsKey.page),
      search: this.route.snapshot.queryParamMap.get(queryParamsKey.search),
      ordering: this.route.snapshot.queryParamMap.get(queryParamsKey.sort),
    };
    this.currentOrdering = this.deserializeQueryParamsToCurrentOrdering(this.route.snapshot.queryParamMap.get(queryParamsKey.sort));

    this.getUsersListFromApi(queryParamsFromUrl);

    queryParamsFromUrl.search && this.searchInputValue.next(queryParamsFromUrl.search);
    this.searchInputValue.pipe(debounceTime(this.usersQuerySearchDelay)).subscribe((inputValue: string) => {
      this.router.navigate([], {
        queryParams: {
          search: inputValue === "" ? null : inputValue
        },
        relativeTo: this.route,
        queryParamsHandling: "merge",
        skipLocationChange: false
      });

      if(this.lastSearchQuery !== inputValue) {
        this.lastSearchQuery = inputValue;
        const queryParams: QueryParamsForGetUsers = {
          page: "1",
          search: inputValue,
          ordering: this.route.snapshot.queryParamMap.get(queryParamsKey.sort),
        };
        this.getUsersListFromApi(queryParams);
      }
    });
  }

  ngDoCheck() {
    if(this.route.snapshot.queryParamMap.get(queryParamsKey.sort)) this.addActiveStyleForSortingArrows(this.route.snapshot.queryParamMap.get(queryParamsKey.sort));
  }

  public getUsersRoleList(): void {
    this.db3DApiBackendClient.getUsersRolesList(environment.db3dBackendDomain, this.wlSlug).subscribe({
      next: (userRolesResponse) => {
        this.usersRoleList = this.buildUsersRoleList(userRolesResponse);
      },
      error: (err) => {
        if(err.status === 403) this.router.navigate([this.navigationService.pageNotFound]);
      }
    });
  }

  private buildUsersRoleList(dataFromApi: Array<IUsersRoleApiResponse>): Array<UsersRole> {
    return dataFromApi.map( (usersRoleJson) => {
      return this.usersRoleFactory.createFromBackendApi(usersRoleJson);
    });
  }

  private buildUsersDataList(dataFromApi: Array<IUserDetailsApiResponse>): Array<UserDetails> {
    return dataFromApi.map( (userJson) => {
      return this.usersDetailsFactory.createFromBackendApi(userJson);
    });
  }

  private deserializeQueryParamsToCurrentOrdering(queryParams: string): {[key: string]: SortDirection} {
    const ordering: {[key: string]: SortDirection} = {};
    if (queryParams) {
      const params = queryParams.split(",");
      params.map((param: string) => {
        if (param[0] === sortDirection.descPrefix) {
          ordering[param.substring(1)] = sortDirection.desc;
        } else {
          ordering[param] = sortDirection.asc;
        }
      });
    }
    return ordering;
  }

  public sortColumn(direction: SortDirection, sortBy: string): void {
    const oppositeDirection = direction === sortDirection.asc ? sortDirection.desc : sortDirection.asc;
    const clickedSortingArrow = this.findSortingArrow(direction, sortBy);
    const unclickedSortingArrow = this.findSortingArrow(oppositeDirection, sortBy);

    if(unclickedSortingArrow.nativeElement.classList.contains(UsersListComponent.sortActiveClassName)) unclickedSortingArrow.nativeElement.classList.remove(UsersListComponent.sortActiveClassName);

    if(clickedSortingArrow.nativeElement.classList.contains(UsersListComponent.sortActiveClassName)) {
      clickedSortingArrow.nativeElement.classList.remove(UsersListComponent.sortActiveClassName);
    } else {
      clickedSortingArrow.nativeElement.classList.add(UsersListComponent.sortActiveClassName);
    }

    this.updateDisplayedUsersList(direction, sortBy);
  }

  private updateDisplayedUsersList(direction: SortDirection, sortByParam: string): void {
    this.updateCurrentOrdering(direction, sortByParam);

    const newOrderingQueryParams = this.serializeCurrentOrderingToQueryParams(this.currentOrdering);

    this.router.navigate([], {
      queryParams: {
        ordering: newOrderingQueryParams
      },
      relativeTo: this.route,
      queryParamsHandling: "merge",
      skipLocationChange: false
    });

    this.getSortedUsersList(newOrderingQueryParams);
  }

  private updateCurrentOrdering(direction: SortDirection, sortByParam: string): void {
    if(this.currentOrdering[sortByParam] === direction) {
      delete this.currentOrdering[sortByParam];
    } else {
      this.currentOrdering[sortByParam] = direction;
    }
  }

  private serializeCurrentOrderingToQueryParams(currentOrdering: {[key: string]: SortDirection}): string {
    const orderingInQueryParams = [];
    for(const [key, value] of Object.entries(currentOrdering)) {
      if(value === sortDirection.asc) {
        orderingInQueryParams.push(key);
      } else if(value === sortDirection.desc) {
        orderingInQueryParams.push(`${sortDirection.descPrefix}${key}`);
      }
    }

    return orderingInQueryParams.toString();
  }

  public getSortedUsersList(newOrderingQuery: string) {
    const queryParams: QueryParamsForGetUsers = {
      page: "1",
      search: this.route.snapshot.queryParamMap.get(queryParamsKey.search),
      ordering: newOrderingQuery,
    };
    this.getUsersListFromApi(queryParams);
  }

  public goToDetails(userId: number): void {
    this.router.navigate([this.navigationService.adminUsersWithId(userId)], {queryParams: this.route.snapshot.queryParams});
  }

  public isUsersListEmpty(): boolean {
    return !(this.users?.length > 0);
  }

  public changePage(pageNumber: number): any {
    const queryParams: QueryParamsForGetUsers = {
      page: pageNumber.toString(),
      search: this.route.snapshot.queryParamMap.get(queryParamsKey.search),
      ordering: this.route.snapshot.queryParamMap.get(queryParamsKey.sort),
    };
    this.getUsersListFromApi(queryParams);
  }

  private addPaginationParamsToUrl(paginationPage: number): void {
    this.router.navigate([], {
      queryParams: {
        page: paginationPage,
      },
      relativeTo: this.route,
      queryParamsHandling: "merge",
      skipLocationChange: false
    });
  }

  public getUsersListFromApi(queryParamsFromUrl?: QueryParamsForGetUsers): void {
    this.usersFetchingInProgress = true;
    this.serverError = false;
    this.db3DApiBackendClient.getUsersList(environment.db3dBackendDomain, queryParamsFromUrl).subscribe({
      next: (usersFromApi) => {
        this.users = this.buildUsersDataList(usersFromApi.items);
        this.pagination = this.usersPaginationFactory.createFromBackendApi(usersFromApi.pagination);
        this.addPaginationParamsToUrl(usersFromApi.pagination.page);
        this.users.length === 0 ? this.noSearchingResults = true : this.noSearchingResults = false;
        this.usersFetchingInProgress = false;
        this.serverError = false;
      },
      error: (err) => {
        if(err.status === 403) this.router.navigate([this.navigationService.pageNotFound]);
        
        this.usersFetchingInProgress = false;
        this.serverError = true;
        this.noSearchingResults = false;
      }
    });
  }

  public addActiveStyleForSortingArrows(orderingQueryParams: string) {
    const params = orderingQueryParams.split(",");
    params.map((param: string) => {
      if(param[0] === sortDirection.descPrefix) {
        this.findSortingArrow(sortDirection.desc, param.substring(1))?.nativeElement.classList.add(UsersListComponent.sortActiveClassName);
      } else {
        this.findSortingArrow(sortDirection.asc, param)?.nativeElement.classList.add(UsersListComponent.sortActiveClassName);
      }
    });
  }

  public findSortingArrow(direction: string, sortBy: string): ElementRef<any> | undefined {
    if (!this.arrows) return undefined;
    return this.arrows.find((arrow: ElementRef<any>) => arrow.nativeElement.id === `${direction}-${sortBy}`);
  }
} 
