import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Designer } from 'src/app/data-models/designer-data.model';
import { NgForm } from '@angular/forms';
import { RegistrationFlowService } from 'src/app/services/registration-flow.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {GlobalSettingsService} from "../../services/settings/global-settings.service";

@Component({
  selector: 'app-registration-veryfication',
  templateUrl: './registration-verification.component.html',
  styleUrls: ['./registration-verification.component.css']
})
export class RegistrationVerificationComponent implements OnInit, OnDestroy {

  @ViewChild('RecomendationForm') registrationForm: NgForm;
  public emailList;
  public initialized = false;
  @Input() public designer: Designer;
  public timerDueDate: Date;
  public errorGlobalMessage = null;
  emailsSent = false;
  private sendingEmails = false;
  private errors: any;

  constructor(
    private registrationFlowService: RegistrationFlowService,
    private globalSettingsService: GlobalSettingsService,
    private http: HttpClient,
  ) {
    this.designer = this.registrationFlowService.getTempUser();
    this.timerDueDate = new Date();
    this.timerDueDate.setDate( this.timerDueDate.getDate() + 2 );
    //this.createEmailList();
  }

  ngOnInit() {
    if (!this.designer){
      this.designer = this.registrationFlowService.getTempUser();
    }
    this.initialized = true;
  }

  ngOnDestroy(): void {
    this.registrationFlowService.reset();
  }

  // createEmailList(){
  //   this.emailList = new Array(0);
  //   this.emailList.push({email: ''});
  // }
  //
  // onAddEmailButtonClicked(){
  //   this.emailList.push({email: ''});
  // }
  // onAddMoreEmailsClicked(){
  //   this.emailsSent = false;
  //   this.errorGlobalMessage = null;
  //   this.createEmailList();
  // }
  //
  // onRemoveEmailButtonClicked(i: any) {
  //   this.emailList.splice(this.emailList.indexOf(i), 1);
  // }
  //
  // onEmailChanged(i: any, event) {
  //   this.emailList[i] = event.target.value;
  // }
  //
  // onRecomendButtonClicked() {
  //   const inputs = Array.from(document.getElementsByClassName('new-form-input-field'));
  //   let atLeastSingleValid = false;
  //   for (const input of inputs){
  //     const inputElememnt = (input as HTMLInputElement);
  //     if (!inputElememnt.checkValidity() && inputElememnt.value!== ''){
  //       return;
  //     }
  //     else if (inputElememnt.checkValidity()){
  //       atLeastSingleValid = true;
  //     }
  //   }
  //   if (!atLeastSingleValid){
  //     return;
  //   }
  //   this.sendRecomendationForm();
  // }
  //
  // inputChanged(email) {
  //   email.errorMessage = null;
  //   if (email === this.emailList[this.emailList.length - 1] && email !== ''){
  //     this.onAddEmailButtonClicked();
  //   }
  // }
  //
  // sendRecomendationForm() {
  //   this.sendingEmails = true;
  //   const recomendationUrl = `${this.globalSettingsService.designerReferalsDomain}/api/designer-referrals/`;
  //   const refererId = this.designer.id; //312
  //   const recomendedEmails = this.emailList.map(email => email.email);
  //
  //   let httpHeaders = new HttpHeaders();
  //   httpHeaders = httpHeaders.set('Content-Type', 'application/json');
  //
  //   const httpBody: any = {};
  //   httpBody.designer_id = refererId;
  //   httpBody.recommended_emails = recomendedEmails;
  //   httpBody.recommended_emails.pop();
  //
  //   this.http.post<any>(recomendationUrl, httpBody, {headers: httpHeaders}).subscribe(
  //     (successResponse) => {
  //       this.sendingEmails = false;
  //       this.errorGlobalMessage = null;
  //       const recomended =  successResponse['valid_emails'];
  //       if (recomended){
  //         for (const recomendedMail of recomended){
  //           this.emailList.splice(this.emailList.indexOf(this.emailList.filter(x => x.email === recomendedMail)[0]), 1);
  //         }
  //       }
  //       const errors = successResponse['errors'];
  //       if (errors){
  //         for (const error of errors){
  //           if (error['error_code'] === 'designer_already_registered'){
  //             this.errorGlobalMessage = 'Some emails are already registered';
  //             this.emailList.filter(x => x.email === error['value']).forEach(y => y.errorMessage = 'This user is already registered');
  //           }
  //         }
  //       }
  //       else {
  //         this.emailsSent = true;
  //       }
  //       if (successResponse['errors']) {
  //         this.errors = successResponse['errors'];
  //       }
  //     },
  //     (errorResponse) => {
  //       this.sendingEmails = false;
  //       this.errorGlobalMessage = 'Something went wrong. Please try again';
  //     }
  //   );
  //
  // }
}




export class RecomendEmail {
  public email: string;
  public errorMessage = null;
}
