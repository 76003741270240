import { AfterViewInit, Component, ElementRef, Input, ViewChild } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: "app-users-list-search",
  templateUrl: "./users-list-search.html",
  styleUrls: ["./users-list-search.component.scss"]
})

export class UsersListSearchComponent implements AfterViewInit {

  @Input() inputValue: BehaviorSubject<string> = new BehaviorSubject("");
  @ViewChild("deleteQueryBtn") deleteQueryBtn: ElementRef;

  constructor() {}

  ngAfterViewInit() {
    this.toggleQueryDeleteBtn(this.inputValue.value.length);
  }

  public onSearchChange(value:string):void {
    this.inputValue.next(value);

    this.toggleQueryDeleteBtn(value.length);
  }

  public removeQuery(): void {
    this.inputValue.next("");
    this.deleteQueryBtn.nativeElement.classList.remove("search-close-btn-visible");
  }

  private toggleQueryDeleteBtn(value: number): void {
    if(value > 0)
      this.deleteQueryBtn.nativeElement.classList.add("search-close-btn-visible");
    else
      this.deleteQueryBtn.nativeElement.classList.remove("search-close-btn-visible");
  }
}
