import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PopupService } from './popup.service';
import { GoogleTagManagerHandlerService } from './google-tag-manager.service';
import { GlobalSettingsService } from './settings/global-settings.service';
import { IntiaroAnalyticsClient } from './analytics/intiaro-analytics.service';

declare var IntiaroAnalyticsManager: any;

@Injectable({ providedIn: 'root' })
export class Model3DDownloadService {

  private modelDownloadRequestFrequency = 3000;
  private download3DEventName = '3DModelRendering';

  private pendingTimeouts = {};

  constructor(
      private http: HttpClient,
      private popupService: PopupService,
      private googleTagManagerHandlerService: GoogleTagManagerHandlerService,
      private globalSettingsService: GlobalSettingsService,
      private intiaroAnalyticsClient: IntiaroAnalyticsClient
  ) {}

  startDownloading(requestId, data) {
    this.send3DModelDownloadNotification(requestId, data, 'pending');
    // this.checkIf3DModelReady(requestId, data);
    this.sendAnalyticsData(data, 'start');
  }

  checkIf3DModelReady(requestId, fileData) {
    const modelDownloadUrl = `${this.globalSettingsService.modelServiceApiDomain}api/mesh-scale-request/${requestId}/`;

    this.http.get<any>(modelDownloadUrl).subscribe(
      response => {
        if (response.status !== 'done') {
          this.retryToCheckIfModelReady(response.request_id, fileData);
        } else {
          // this.send3DModelDownloadNotification(requestId, fileData, 'done', response.converted_mesh_url, true);
          this.sendAnalyticsData(fileData, 'finish');
        }
      },
      error => {
        this.handleRequestError(error, requestId, fileData);
      }
    );
  }

  retryToCheckIfModelReady(requestId, fileData) {
    const timeut = setTimeout(() => {
      this.checkIf3DModelReady(requestId, fileData);
    }, this.modelDownloadRequestFrequency);

    this.pendingTimeouts[requestId] = timeut;
  }

  show3DDownloadFailPopup() {
    const options = {
        title: 'Requesting 3D model',
        text: [
        'Your request could not be completed. Please try again later.',
        ],
        buttons: [
          {
              text: 'Close',
              callback: () => { this.popupService.hidePopup(); }
          }
        ]
    };

    this.popupService.showPopup(options);
  }

  send3DModelDownloadNotification(modelServiceJobUuid, data, currentStatus, currentModelUrl = '', update = false) {
    // this.notificationsService.sendDownload3DModelNotification();
  }

  prepareModel3DDownloadNotificationData(requestId, data, currentStatus, currentModelUrl = '') {
    return {
      uuid: requestId,
      type_specific_data: {
        thumbnail_url: data.thumbnail_url,
        mesh_format: data.mesh_format,
        filename: data.filename,
        status: currentStatus,
        model_url: currentModelUrl
      }
    };
  }

  sendAnalyticsData(data, status) {
    let dataObj = data;
    dataObj.status = status;
    if (data.product) {
      dataObj = {...dataObj, ...data.product };
    }

    this.googleTagManagerHandlerService.sendEvent(this.download3DEventName, dataObj);
    this.intiaroAnalyticsClient.sendEvent(this.download3DEventName, { Status: status, Value: data});
  }

  handleRequestError(error, requestId, fileData) {

    switch (error.status) {
      case 404:
        // Do not retry if request could not be found
        throw new Error(`3D model request ${requestId} not found.`);

      default:
        this.retryToCheckIfModelReady(requestId, fileData);
        break;
    }
  }

  stopAskingForPendingRequests() {
    const timeouts = Object.keys(this.pendingTimeouts);

    timeouts.forEach(id => {
      clearTimeout(this.pendingTimeouts[id]);
    });
  }
}
