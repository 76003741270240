import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject} from "rxjs";

export interface IDyanmicPopupContent {
  hideCloseButton?: boolean,
  ngDynamicComponent?:{
    component: any,
    inputs?:{}
  };
}
@Injectable()
export class DyanmicPopupService {

  public popupContent = new BehaviorSubject<any>(null);
  public hidePopupEvent = new Subject();
  
  private setBodyData(options?: IDyanmicPopupContent): IDyanmicPopupContent {
    let content: IDyanmicPopupContent = { hideCloseButton: false };
    if (options) content = {...content, ...options};
    return content;
  }

  public showPopup(options?: IDyanmicPopupContent) {
    if (!this.popupContent.value) {
      const content = this.setBodyData(options);
      this.popupContent.next(content);      
    }
  }

  public getDefaultContent(): IDyanmicPopupContent {
    return this.setBodyData();
  }

  public hidePopup(): void {
    this.hidePopupEvent.next(true);
    this.popupContent.next(null);
  }
}
