import { Injectable } from "@angular/core";
import { WhiteLabelConfigurationService } from "./white-labels/white-label-configuration.service";
import { filter, take } from "rxjs";
import { WhiteLabelSetupConfiguration } from "../data-models/wl-account-setup/wl-config-data.model";

@Injectable()
export class IntiaroQrCodeTemplateService {

  private readonly webComponentName: string = "intiaro-qr-template";

  private _isEnable: boolean = false;

  public get isEnable(): boolean {
    return this._isEnable;
  }

  private elementStyle: HTMLStyleElement;
  private intiaroCustomComponent: HTMLElement;

  private readonly stylesTemplate: string = `
    intiaro-qr-template{
      visibility: hidden;
      display: none;
    }
    @media print{
      body{
        margin:0;
        padding:0;
        height: none;
      }
      app-root, us-button{
        visibility: hidden;
        display: none;
      }
      intiaro-qr-template{
        box-sizing: border-box;
        visibility: visible;
        display: flex;
        flex-shrink: 1;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
      @page {
        size: auto;
        margin: 0;
      }
    }
  `;

  constructor(private whiteLabelConfigurationService: WhiteLabelConfigurationService) {
  
    this.whiteLabelConfigurationService.whiteLabelSetupConfiguration.pipe(
      filter((whiteLabelSetupConfiguration: WhiteLabelSetupConfiguration) => whiteLabelSetupConfiguration.slug !== ""),
      take(1)
    ).subscribe((whiteLabelSetupConfiguration: WhiteLabelSetupConfiguration) => {

      const script: HTMLScriptElement = document.createElement("script");

      script.setAttribute("src", whiteLabelSetupConfiguration.qrCodeTemplateUrl);
      script.onerror = () => this._isEnable = false;
      script.onload = () => this._isEnable = true;
      
      document.head.appendChild(script);
    });

    this.elementStyle = document.createElement("style");
    this.elementStyle.setAttribute("qr-code-generator-styles", "");
    document.head.appendChild(this.elementStyle);
  }

  public createTemplateInstance(productName: string, brandName: string, qrCodeContentUrl:string): HTMLElement {
    this.elementStyle.textContent = this.stylesTemplate;
    this.intiaroCustomComponent = document.createElement(this.webComponentName); 

    this.intiaroCustomComponent.setAttribute("product-name", productName);
    this.intiaroCustomComponent.setAttribute("brand-name", brandName);
    this.intiaroCustomComponent.setAttribute("qr-code-content", qrCodeContentUrl);  
    document.body.appendChild(this.intiaroCustomComponent);

    return this.intiaroCustomComponent;
  }

  public removeTemplateInstance(): void {
    this.elementStyle.textContent = "";
    this.intiaroCustomComponent.remove();
  }

  public printTemplate(): void {
    window.print();
  }

}