/* eslint-disable arrow-body-style */
/* eslint-disable quotes */
import {Component} from "@angular/core";

@Component({
  selector: "app-password-conditions",
  templateUrl: "./password-conditions.component.html",
  styleUrls: ["./password-conditions.component.scss", "../../authentication-module.scss"]
})
export class PasswordConditionsComponent {
  
  public readonly passwordConditions: Array<{readonly key: string, value: boolean, readonly checkCondition: (password: string)=> boolean}> = [
    {
      key: "8 characters",
      value: false,
      checkCondition: (password: string) => password.length > 7
    },
    {
      key: "1 digit",
      value: false,
      checkCondition: (password: string) => /\d/.test(password)
    },
    {
      key: "1 lowercase letter",
      value: false,
      checkCondition: (password: string) => /[a-z]/.test(password)
    },
    {
      key: "1 uppercase letter",
      value: false,
      checkCondition: (password: string) => /[A-Z]/.test(password)
    }
  ];

  constructor() {}

  public checkPasswordConditions(password: string): void {
    this.passwordConditions.forEach((conditionItem) => {
      conditionItem.value = conditionItem.checkCondition(password);
    });
  }

  public getIconUrl(conditions: boolean): string {
    return conditions
      ? './../../../../assets/images/icons/registration/password_condition_check.svg' 
      : './../../../../assets/images/icons/registration/password_condition_cross.svg';
  }
}
