import { AfterViewChecked, ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { MyProfileAbstractComponent, requestErrorsMessages } from "../../my-profile-abstract.component";
import { Db3DApiBackendClient } from "src/app/services/api/db3d-api-backend-client.service";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { WhiteLabelConfigurationService } from "src/app/services/white-labels/white-label-configuration.service";
import { NgForm } from "@angular/forms";
import { ComponentCanDeactivate } from "src/app/services/guards/pendingChangesGuard";
import { PopupService } from "src/app/services/popup.service";
import { Observable, Subject } from "rxjs";
import { ToastService, ToastType } from "src/app/services/toast.service";
import { UserDetailsService } from  "src/app/services/user-details.service";
import { NavigationService, OutletsRegistry } from "src/app/services/NavigationService.service";

@Component({
  selector: "app-basic-info-edit",
  templateUrl: "./basic-info-edit.component.html",
  styleUrls: ["../../my-profile-subcomponents.component.scss"],
})

export class BasicInfoEditComponent extends MyProfileAbstractComponent implements ComponentCanDeactivate, OnInit, AfterViewChecked {

  public requestErrorMsg: string;
  public nameInputError: string;
  public phoneInputError: string;
  private confirmNavigationSubject: Subject<boolean> = new Subject<boolean>();
  private isRequestProcessing: boolean = false;
  private isCancelProcessing: boolean = false;
  public priorityForUserDecisionOfRedirection: boolean = false;
  phoneNumberRequired: boolean = false;

  @ViewChild("basicInfoForm") basicInfoForm: NgForm;
  @ViewChild("phoneNumber") phoneNumber: NgForm;

  constructor(
    db3DApiBackendClient: Db3DApiBackendClient,
    wlConfigService: WhiteLabelConfigurationService,
    userDetailsService: UserDetailsService,
    private router: Router,
    private navigationService: NavigationService,
    private popupService: PopupService,
    private toastService: ToastService,
    private changeDetector: ChangeDetectorRef
  ) {
    super(db3DApiBackendClient, wlConfigService, userDetailsService);
  }

  @HostListener("window:beforeunload", ["$event"])
  windowBeforeUnload($event: BeforeUnloadEvent) {
    if(!this.canLeavePage()) $event.returnValue = "Your data will be lost";
  }
  canDeactivate(): Observable<boolean> | boolean {
    const popupOptionsUnsaved = {
      title: "You have unsaved changes",
      text: ["Save changes before leaving"],
      buttons: [
        {
          text: "Save changes",
          callback: () => {
            this.priorityForUserDecisionOfRedirection = true;
            this.saveForm();
            this.confirmNavigationSubject.next(true);
            this.popupService.hidePopup();
          }
        }
      ],
      secondaryButtons: [
        {
          text: "Don't save and leave",
          callback: () => {
            this.confirmNavigationSubject.next(true);
            this.popupService.hidePopup();
          }
        }
      ]
    };
    const popupOptionsInvalid = {
      title: "You have invalid data in the form",
      text: ["Correct and save changes before leaving"],
      buttons: [
        {
          text: "Correct",
          callback: () => {
            this.popupService.hidePopup();
          }
        }
      ],
      secondaryButtons: [
        {
          text: "Leave",
          callback: () => {
            this.confirmNavigationSubject.next(true);
            this.popupService.hidePopup();
          }
        }
      ]
    };
    if(this.canLeavePage()) {
      return true;
    } else {
      this.popupService.showPopup(this.basicInfoForm.valid ? popupOptionsUnsaved : popupOptionsInvalid);
      return this.confirmNavigationSubject.asObservable();
    }
  }

  ngAfterViewChecked(): void {
    this.setPhoneNumberRequiredFlag();
    this.changeDetector.detectChanges();  
  }

  public cleanErrorMesseges() {
    this.requestErrorMsg = "";
    this.nameInputError = "";
    this.phoneInputError = "";
  }

  public cancelForm() {
    this.isCancelProcessing = true;
    this.router.navigate([this.navigationService.myProfile, { outlets: { [OutletsRegistry.basicInfo]: null } }]);
  }

  public saveForm() {
    if(!this.isInitialDataTheSameAsNewDataInForm() && this.basicInfoForm.valid && !this.isRequestProcessing && !this.phoneNumberRequired) this.patchNewUserData();
  }

  public isInitialDataTheSameAsNewDataInForm(): boolean {
    return (this.userName === this.userDetails.name && this.userPhoneNumber === this.userDetails.phoneNumber);
  }

  public setPhoneNumberRequiredFlag(): void {
    this.phoneNumberRequired = (this.userDetails?.phoneNumber && !this.userPhoneNumber) ? true : false;
  }

  private patchNewUserData() {
    this.isRequestProcessing = true;
    const formData: FormData = new FormData();
    if(this.userName) formData.append("name", this.userName);
    if(this.userPhoneNumber) formData.append("phone_number", this.userPhoneNumber);

    this.db3DApiBackendClient.updateUserDetails(environment.db3dBackendDomain, this.userDetails.id, formData).subscribe({
      next: (resp) => {
        this.userDetailsService.updateCurrentUser(resp);
        this.toastService.showToast("Changes saved!", ToastType.success);
        if(!this.priorityForUserDecisionOfRedirection) this.router.navigate([this.navigationService.myProfile]);
      },
      error: (err) => {
        this.toastService.showToast("Changes unsaved!", ToastType.error);
        this.resetForm();
        this.isRequestProcessing = false;

        this.requestErrorMsg = requestErrorsMessages.general;
        if(err.status === 400) this.requestErrorMsg = requestErrorsMessages.err400;
        if(err.status === 403) this.requestErrorMsg = requestErrorsMessages.err403;
        if(err.status === 404) this.requestErrorMsg = requestErrorsMessages.err404;
        if(err.status >= 500) this.requestErrorMsg = requestErrorsMessages.err500;
        if(err.error.phone_number) this.phoneInputError = err.error.phone_number;
        if(err.error.full_name) this.nameInputError = err.error.full_name;
      },
    });
  }

  private resetForm(): void {
    this.userName = this.userDetails.name;
    this.userPhoneNumber = this.userDetails.phoneNumber;
  }

  private canLeavePage(): boolean {
    return this.isInitialDataTheSameAsNewDataInForm() || this.isRequestProcessing || this.isCancelProcessing;
  }

  setPhoneNumberErrorMessage(): string {
    if(!this.phoneNumber?.valid) return "Invalid phone number";
    if(this.phoneNumberRequired) return "Phone number is required";
    if(this.phoneInputError) return this.phoneInputError;
  }
}
