import { Component } from "@angular/core";
import { FooterService } from "../../services/footer.service";
import { NavigationService } from "src/app/services/NavigationService.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class FooterComponent  {

  public currentYear: number;

  constructor(
    public footerService: FooterService,
    public navigationService: NavigationService
  ) {}

  getCurrentYear(): number {
    this.currentYear = new Date().getFullYear();
    return this.currentYear;
  }

}
