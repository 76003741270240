import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { WhiteLabelSetupConfiguration } from "src/app/data-models/wl-account-setup/wl-config-data.model";
import { WhiteLabelConfigurationService } from "src/app/services/white-labels/white-label-configuration.service";
import { WhiteLabelContextService } from "src/app/services/white-labels/white-label-context.service";

enum validationErrorMessages {
  noFile = "File is missing.",
  invalidFileExtensions = "Invalid file extension. Only .jpg and .png are allowed.",
  tooBigSize = "File size exceeds the maximum limit (2MB).",
  tooBigDimensions = "Image dimensions are too big (maximum 180x65 pixels)."
}

@Component({
  selector: "app-logo-upload",
  templateUrl: "./logo-upload.component.html",
  styleUrls: ["./logo-upload.component.css"]
})

export class LogoUploadComponent implements OnInit {
  imagePreview = "";
  private validationResult: Promise<{ valid: boolean; error?: string }>;
  private fileInput: HTMLInputElement;
  private fileName: string;
  public errorMessage: string = "";
  static readonly screenWidthLimit: number = 900;
  static readonly logoImageRequirements = {
    allowedImageExtensions: ["jpg", "png"],
    maxSizeInBytes: (2 * 1024 * 1024),
    imageWidthLimit: 180,
    imageHeightLimit: 65,
    maxFileNameLength: 32,
    maxFileNameLengthMobile: 21,
  };

  @ViewChild("fileButton") fileButtonEl: ElementRef;
  @ViewChild("validationImageResult") validationImageResultEl: ElementRef;

  constructor(
    private whiteLabelConfigurationService: WhiteLabelConfigurationService,
    private whiteLabelContextService: WhiteLabelContextService
  ) {}

  ngOnInit(): void {
    this.whiteLabelConfigurationService.whiteLabelSetupConfiguration.subscribe( (wlConfiguration: WhiteLabelSetupConfiguration) => {
      this.imagePreview = wlConfiguration.logo;
    });
  }

  async onChangeLogoImage(event: Event): Promise<void> {
    const fileUpload = event.target as HTMLInputElement;

    if (fileUpload.files.length == 1) {
      const newLogoFile = fileUpload.files[0];
      this.validationResult = this.validateImage(newLogoFile);

      if ((await this.validationResult).valid) {
        this.errorMessage = "";
        await this.setPreviewFileName(event);
        const whiteLabelSlug = this.whiteLabelContextService.getCurrentWhiteLabelSlug();
        this.whiteLabelConfigurationService.updateWhiteLabelLogo(whiteLabelSlug, newLogoFile);
        this.whiteLabelConfigurationService.serverErrorMsg.subscribe( (data) => {
          if(data) {
            this.errorMessage = data;
            this.imagePreview = "";
            this.fileButtonEl.nativeElement.textContent = "";
          }
        });
      } else  
        this.errorMessage = (await this.validationResult).error;      
      
      fileUpload.value = "";
    }
  }

  async setPreviewFileName(event: Event): Promise<void> {
    this.fileInput = event.target as HTMLInputElement;
    this.fileName = this.fileInput.files[0].name;
    const finalName = await this.adjustFileName(this.fileName);
    this.fileButtonEl.nativeElement.textContent = finalName;
  }

  async validateImage(newLogoFile: File): Promise<{ valid: boolean; error?: string }> {

    if (!newLogoFile) 
      return { valid: false, error: validationErrorMessages.noFile };
    

    this.fileName = newLogoFile.name.toLowerCase();
    const fileExtension: string = this.fileName.split(".").pop();

    if (!LogoUploadComponent.logoImageRequirements.allowedImageExtensions.includes(fileExtension)) {
      this.fileButtonEl.nativeElement.textContent = "";
      return Promise.resolve({ valid: false, error: validationErrorMessages.invalidFileExtensions });
    }

    if (newLogoFile.size > LogoUploadComponent.logoImageRequirements.maxSizeInBytes) {
      this.fileButtonEl.nativeElement.textContent = "";
      return Promise.resolve({ valid: false, error: validationErrorMessages.tooBigSize });
    }

    const image = new Image();
    const imageSource = image.src = URL.createObjectURL(newLogoFile);

    return new Promise((resolve) => {
      image.onload = () => {
        const width = image.width;
        const height = image.height;

        if (width <= LogoUploadComponent.logoImageRequirements.imageWidthLimit && height <= LogoUploadComponent.logoImageRequirements.imageHeightLimit) {
          this.imagePreview = imageSource;

          resolve({ valid: true });
        } else {
          this.fileButtonEl.nativeElement.textContent = "";
          resolve({ valid: false, error: validationErrorMessages.tooBigDimensions });
        }
      };
    });
  }

  async adjustFileName(fileName: string): Promise<string> {
    const screenWidth = window.innerWidth;
    let finalFileName = fileName;

    if (fileName.length >= LogoUploadComponent.logoImageRequirements.maxFileNameLength && screenWidth > LogoUploadComponent.screenWidthLimit) 
      finalFileName = this.cutFileName(fileName, LogoUploadComponent.logoImageRequirements.maxFileNameLength);
    
    if (fileName.length >= LogoUploadComponent.logoImageRequirements.maxFileNameLengthMobile && screenWidth < LogoUploadComponent.screenWidthLimit) 
      finalFileName = this.cutFileName(fileName, LogoUploadComponent.logoImageRequirements.maxFileNameLengthMobile);
    
    return finalFileName;
  }

  cutFileName(fileName: string, maxFileNameLength: number): string {
    const textMiddle = "...";
    const charToSave = Math.floor((maxFileNameLength - textMiddle.length) / 2);
    const finalFileName = fileName.slice(0, charToSave) + textMiddle + fileName.slice(-charToSave);
    return finalFileName;
  }
}
