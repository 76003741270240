import { Component, OnDestroy, OnInit } from "@angular/core";
import { environment } from "../../../environments/environment";
import { ActivatedRoute, Router } from "@angular/router";
import { ProductDesign } from "src/app/data-models/product-design";
import { FooterService } from "src/app/services/footer.service";
import { NavbarService } from "src/app/services/navbar.service";
import { WhiteLabelConfigurationService } from "src/app/services/white-labels/white-label-configuration.service";
import { IProductDesignApiResponse, ProductDesignFactory } from "src/app/factories/product-design-factory";
import { Subject } from "rxjs";
import { ProductDesignEditComponent } from "../product-design-edit/product-design-edit.component";
import { Db3DApiBackendClient } from "src/app/services/api/db3d-api-backend-client.service";
import { UsersDetailsFactory } from "src/app/factories/user-details-factory";
import { UserDetails } from "src/app/data-models/manage-users/user-details.model";
import { IntiaroAnalyticsClient, IntiaroAnalyticsConstants } from "src/app/services/analytics/intiaro-analytics.service";

declare var getIntiaroPlayer: any;
declare var clearIntiaroInstances: any;

@Component({
  selector: "app-my-design-preview",
  templateUrl: "./my-design-preview.component.html",
  styleUrls: ["./my-design-preview.component.scss"]
})

export class MyDesignPreviewComponent implements OnInit, OnDestroy {

  public showConfiguration: boolean = false;
  public tearsheetTemplateId: string;
  public productDesignId: string;
  protected readonly environment = environment;
  public productDesign: ProductDesign;
  private productDesignFactory: ProductDesignFactory = new ProductDesignFactory();
  public designOwner: UserDetails;
  private designOwnerFactory: UsersDetailsFactory = new UsersDetailsFactory();
  public configurationDetails: Subject<any> = new Subject();

  constructor(
    private footerService: FooterService,
    private navbarService: NavbarService,
    private activatedRoute: ActivatedRoute,
    private whiteLabelConfigurationService: WhiteLabelConfigurationService,
    private router: Router,
    private db3DApiBackendClient: Db3DApiBackendClient,
    private intiaroAnalyticsClient: IntiaroAnalyticsClient,
  ) {}

  ngOnInit() {
    this.navbarService.hide();
    this.footerService.hide();
    this.productDesignId = this.activatedRoute.snapshot.paramMap.get("id");
    this.getProductDesignData();

    this.whiteLabelConfigurationService.whiteLabelSetupConfiguration.subscribe((template) => {
      this.tearsheetTemplateId = template.pdfTemplate;
    });
  }

  getProductDesignData(): void {
    this.db3DApiBackendClient.getProductDesign(environment.db3dBackendDomain, this.productDesignId).subscribe({
      next: (productDesignData: IProductDesignApiResponse) => {
        if(productDesignData !== undefined) {
          this.productDesign = this.productDesignFactory.createFromBackendApi(productDesignData);
          this.sendPreviewProductDesignInitiatedEvent(this.productDesign);
        }
      },
      error(err) {
        if(err.status === 404)
          this.router.navigate(["/page-not-found"]);
      },
    });
  }

  /* below code is needed for block with configuration details (<app-design-configuration>)*/
  private get intiaroInstance():any {
    return getIntiaroPlayer(ProductDesignEditComponent.defaultConfiguratorId);
  }

  public intiaro360PlayerReady(_value:boolean): void {
    this.getDesignConfiguration();
  }

  private getDesignConfiguration(): void {
    this.intiaroInstance.getProductOptions(true,
      (productOptions: any) => {
        this.configurationDetails.next(productOptions);
      },
    );
  }
  // end of block with configuration details

  private sendPreviewProductDesignInitiatedEvent(productDesign: ProductDesign): void {
    const eventContext = {
      custom_configuration_id: productDesign.id,
      product_configuration_id: productDesign.productId,
      brand_name: productDesign.brand_name,
      product_name: productDesign.productName,
      name: productDesign.name,
    };
    this.intiaroAnalyticsClient.sendEvent(IntiaroAnalyticsConstants.AnalyticsAttrPreviewProductDesignInitiated, eventContext);
  }

  ngOnDestroy(): void {
    this.navbarService.show();
    this.footerService.show();
    clearIntiaroInstances();
  }
}
