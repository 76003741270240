import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { NavigationService } from "src/app/services/NavigationService.service";

@Component({
  selector: "app-registration-code",
  templateUrl: "./registration-code.component.html"
})
export class RegistrationCodeComponent implements OnInit {

  private registrationCode: any;
  private cookieName = "db3dregistrationCodeCoockie";

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cookieService: CookieService,
    public navigationService: NavigationService
  ) {
    this.activatedRoute.paramMap.subscribe((params) => {
      this.registrationCode = params.get("registration_code");
    });
  }

  ngOnInit() {
    this.cookieService.set(this.cookieName, this.registrationCode, 9999);
    this.router.navigate([this.navigationService.registration], {relativeTo: this.activatedRoute, queryParamsHandling: "merge"});
  }

}
