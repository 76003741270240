/* eslint-disable curly */
/* eslint-disable arrow-body-style */
import { ChangeDetectorRef, Component, ComponentRef, ElementRef, OnInit, ViewChild, ViewContainerRef, AfterViewInit, OnDestroy, DoCheck } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "../../../environments/environment";
import { ProductConfig, Category } from "../../data-models/product-config";
import { IProductConfigApiResponse, ProductConfigFactory } from "../../factories/product-config-factory";
import { ProductDetails } from "../../data-models/types";
import { ProductConfigConfigurator360Component } from "../../components/product-config-configurator360/product-config-configurator360.component";
import { ProductDesignConfigurator360Component } from "../../components/product-design-configurator360/product-design-configurator360.component";
import { Instance360Component, Product360InstancesService } from "src/app/services/product-config-360-instances.service";
import { Subscription, Subject, skip, take, tap, concatMap, of } from "rxjs";
import { PimConfigurationKeyMapping, PriceCalculatorService } from "src/app/services/price-calculator.service";
import { ModularConfigFactory, ModularConfig } from "src/app/factories/modular-configuration-factory";
import { UserService } from "src/app/services/authentication/user.service";
import { RedirectService } from "src/app/services/redirect.service";
import { ListPrice } from "src/app/data-models/price.model";
import { CurrencyService } from "src/app/services/currencyService";
import { IntiaroAnalyticsClient } from "src/app/services/analytics/intiaro-analytics.service";
import { utmQrCodeSource, productDesignViewQUeryParams } from "src/app/components/product-design/product-design.component";
import { NavigationService } from "src/app/services/NavigationService.service";
import { NavbarService } from "src/app/services/navbar.service";
import { Db3DApiBackendClient } from "src/app/services/api/db3d-api-backend-client.service";

type ProductConfigurationInitiatedAnalyticsEventBody = {
  product_configuration_id: string;
  custom_configuration_id?: string;
  design_id?: string;
};

declare var getIntiaroPlayer: any;
declare var clearIntiaroInstances: any;

@Component({
  selector: "app-product-config",
  templateUrl: "./product-config.component.html",
  styleUrls: ["./product-config.component.scss"]
})

export class ProductConfigComponent implements OnInit, AfterViewInit, OnDestroy, DoCheck {

  static readonly queryParamsKeyCustomConfigId: string = "custom-config-id";
  static readonly productListPath: string = "product-list";

  @ViewChild("targetEmbed360Instance", { read: ElementRef }) private targetEmbed360Instance: ElementRef;

  public productConfigId: string;
  public productConfig: ProductConfig;
  protected readonly environment = environment;
  private productConfigFactory: ProductConfigFactory = new ProductConfigFactory();
  public productDetails: ProductDetails | undefined;
  public isSaveDesignFormVisible = false;
  public customConfigId: string = "";
  public configuratorId: string = "";
  public configurationDetails: Subject<any> = new Subject();
  public isTabsActive = false;

  private modularConfigFactory: ModularConfigFactory = new ModularConfigFactory();
  public productPrice: number;
  public productPriceCurrency: string = "";

  public priceDetails: ListPrice = null;

  private isConfigurator360LoadedSubscription: Subscription;
  private instance360ComponentRef: ComponentRef<Instance360Component>;
  public isSectionalBuilderOpen: boolean = false;
  public isProductOptionsReady: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private product360InstancesService: Product360InstancesService,
    private priceCalculatorService: PriceCalculatorService,
    private userService: UserService,
    private changeDetectorRef: ChangeDetectorRef,
    private viewContainerRef: ViewContainerRef,
    private currencyService: CurrencyService,
    private intiaroAnalyticsClient: IntiaroAnalyticsClient,
    public navigationService: NavigationService,
    private navbarService: NavbarService,
    private db3DApiBackendClient: Db3DApiBackendClient
  ) {}

  ngOnInit(): void {
    this.customConfigId = this.activatedRoute.snapshot.queryParamMap.get(ProductConfigComponent.queryParamsKeyCustomConfigId);
    this.configuratorId = this.get360ConfiguratorId(!!this.customConfigId);
    this.productConfigId = this.activatedRoute.snapshot.paramMap.get("id");

    this.sendIntiaroAnalyticsEvents(this.productConfigId, this.customConfigId);
  }

  ngDoCheck(): void {
    if (document.querySelector(".intiaro360-wrapper")) {
      if (document.body.classList.contains("intiaro360-full-screen") && document.querySelector(".intiaro360-wrapper.full-screen")) {
        this.navbarService.hide();
      } else {
        this.navbarService.show();
      }
    }
  }

  ngAfterViewInit(): void {
    this.getProductConfigData();
  }

  private sendIntiaroAnalyticsEvents(productConfigId: string, customConfigId: string) {
    const analyticsEventBody: ProductConfigurationInitiatedAnalyticsEventBody = {
      product_configuration_id: productConfigId,
    };
    if(customConfigId) analyticsEventBody.custom_configuration_id = customConfigId;
    const designId = this.activatedRoute.snapshot.queryParamMap.get(productDesignViewQUeryParams.designId);
    if(designId) analyticsEventBody.design_id = designId;

    this.intiaroAnalyticsClient.sendEvent(
      "productConfigurationInitiated",
      analyticsEventBody
    );

    if(this.activatedRoute.snapshot.queryParamMap.get(utmQrCodeSource.name) === utmQrCodeSource.value) {
      this.intiaroAnalyticsClient.sendEvent(
        "productConfigurationInitiatedAfterQrCodeScan",
        analyticsEventBody
      );
    }
  }

  async getProductConfigData(): Promise<void> {  
  
    this.db3DApiBackendClient.getProductConfig(environment.db3dBackendDomain, this.productConfigId).subscribe({
      next: (productConfigData: IProductConfigApiResponse) => {
        if(productConfigData !== undefined) {
          this.productConfig = this.productConfigFactory.createFromBackendApi(productConfigData);
          this.setProductDetailsData(
            this.productConfig.name,
            this.productConfig.brand_name,
            this.productConfig.productVersionName,
            this.productConfig.categories
          );
        }
        this.embed360InstanceComponent();
      },
      error: (err) => {
        if(err.status === 404)
          this.router.navigate([this.navigationService.pageNotFound]);
      },
    });
  
  }

  public embed360InstanceComponent():void {

    this.instance360ComponentRef = this.isCustomConfiguration()
      ? this.product360InstancesService.createProductDesignConfigurator360ComponentRef(
        this.customConfigId,
        this.productConfig.pim_tenant_id,
        this.productConfig.pim_context,
        this.viewContainerRef,
        this.productConfig.saveAndRenderStrategy
      )
      : this.product360InstancesService.createProductConfigConfigurator360ComponentRef(
        this.productConfig.id,
        this.productConfig.pim_tenant_id,
        this.productConfig.pim_context,
        this.viewContainerRef,
        this.environment.intiaroConfiguratorDomainTemplateKey,
        "product-config-360player",
        this.productConfig.saveAndRenderStrategy
      );

    this.changeDetectorRef.detectChanges();
    this.targetEmbed360Instance.nativeElement.appendChild(this.instance360ComponentRef.location.nativeElement);
    this.instance360ComponentRef.changeDetectorRef.detectChanges();

    this.isConfigurator360LoadedSubscription = this.instance360ComponentRef.instance.isConfigurator360Loaded.subscribe((_) => {
      this.addSectionalBuilderEventObserver();
      this.setCurrentProductOptions();

      if (this.isPriceEnable()) {
        this.assignCurrencyChange();
      }
      this.addConfigurationChangedListener();
    });
  }

  private assignCurrencyChange(): void {

    const oninitPrice = async() => {
      if (this.isCustomConfiguration()) {
        this.priceDetails = await this.priceCalculatorService.publicCalculate(PimConfigurationKeyMapping.Custom, this.customConfigId, this.productConfig.pim_tenant_id, this.productConfig.pim_context);
      } else {
        this.priceDetails = await this.priceCalculatorService.publicCalculate(PimConfigurationKeyMapping.Default, this.productConfigId, this.productConfig.pim_tenant_id, this.productConfig.pim_context);
      }
    };

    const onCurrencyChange = async() => {
      this.priceDetails = null;
      const changedConfig = await this.intiaroInstance.getModularConfiguration();
      this.priceDetails = await this.priceCalculatorService.getPriceFromPim(changedConfig, this.productConfig.pim_context, this.productConfig.pim_tenant_id);
    };

    this.currencyService.currencyChange.pipe(
      concatMap((value, index) => index === 0
        ? of(value).pipe(tap(() => oninitPrice()))
        : of(value)
      ), skip(1)).subscribe(() => onCurrencyChange());
  }

  private addSectionalBuilderEventObserver():void {

    this.intiaroInstance.registerListener(
      "isSectionalBuilderOpen",
      (response:any) => {
        this.isSectionalBuilderOpen = response.isSectionalBuilderOpen;
        this.changeDetectorRef.detectChanges();
      });
  }

  private setCurrentProductOptions(): void {
    getIntiaroPlayer(this.configuratorId).getProductOptions(true,
      (productOptions: any) => {
        if(this.isConfigurationDetailsVisible()) this.configurationDetails.next(productOptions);
        this.isTabsActive = true;
        this.isProductOptionsReady = (productOptions?.elements.length > 0);  
        this.changeDetectorRef.detectChanges();
      },
    );
  }

  private hidePrice(): void {
    this.priceDetails = null;
  }

  private addConfigurationChangedListener(): void {

    this.intiaroInstance?.eventEmitter?.on(
      this.intiaroInstance.configurationChangeInitiatedEventName,
      () => this.hidePrice()
    );

    this.intiaroInstance.registerListener("configurationChanged", async(changedConfig: ModularConfig) => {
      this.setCurrentProductOptions();
      if (this.isPriceEnable()) {
        this.priceDetails = await this.priceCalculatorService.getPriceFromPim(changedConfig, this.productConfig.pim_context, this.productConfig.pim_tenant_id);
      }
    });
  }

  public isPriceEnable(): boolean {
    return this.userService.canUserViewPrices() && !!this.productConfig.pim_context;
  }

  setProductDetailsData(productName: string, brandName: string, programName: string, categories: Array<Category> ): void {
    const concatenatedCategories = categories.map( (category: any) => category.name ).join(", ");
    this.productDetails = {
      productName: productName,
      brandName: brandName,
      programName: programName,
      categories: concatenatedCategories
    };
  }

  get360ConfiguratorId(isCustomConfig: boolean): string {
    return isCustomConfig
      ? ProductDesignConfigurator360Component.defaultConfiguratorId
      : ProductConfigConfigurator360Component.defaultConfiguratorId;
  }

  private get intiaroInstance():any {
    return getIntiaroPlayer(this.configuratorId);
  }

  async saveButtonClicked(): Promise<void> {
    if(this.userService.isLoggedUser()) {
      if (this.intiaroInstance.isSectionalBuilderOpen) {
        return;
      }
      this.isSaveDesignFormVisible = true;
    } else {
      this.intiaroInstance.saveConfiguration( (configId: string) => {
        this.router.navigate([this.navigationService.login], {
          queryParams: {[RedirectService.queryParamKey]: ProductConfigComponent.getPathToCustomisedProduct(this.productConfig.id, configId)},
        });
      });
    }
  }

  static getPathToCustomisedProduct(productId: string, customConfigId: string): string {
    return `${ProductConfigComponent.productListPath}/${productId}?${ProductConfigComponent.queryParamsKeyCustomConfigId}=${customConfigId}`;
  }

  goToProductList(): void {
    this.router.navigate([this.navigationService.productList], {
      queryParams: { [ProductConfigComponent.queryParamsKeyCustomConfigId]: undefined,},
      queryParamsHandling: "merge"
    });
  }

  isCustomConfiguration(): boolean {
    return !!this.customConfigId;
  }

  hideDesignSaveForm(event: any): void {
    if(event) {
      this.isSaveDesignFormVisible = false;
    }
  }

  ngOnDestroy(): void {
    this.isConfigurator360LoadedSubscription?.unsubscribe();
    clearIntiaroInstances();
  }

  public isConfigurationDetailsVisible(): boolean {
    if(this.userService.isLoggedUser()) return this.userService.canViewProductConfigDetails();
  }

  public isSaveButtonDisabled(): boolean {
    return this.isSectionalBuilderOpen || !this.isProductOptionsReady;
  }
}
