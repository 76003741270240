import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.css']
})
export class QrCodeComponent implements OnInit {

  @Input() content: string;
  @Input() size?: string = '256';
  @Input() errorCorrectionLevel?:string = 'M'; //Valid values: "L", "M", "Q", "H" - where "L" is the lowest amount of redundancy, and "H" is the highest.
  @Input() margin?: number = 4;
  @Input() centerImageSrc?: string;
  @Input() centerImageSize?: string;

  constructor() { }

  ngOnInit(): void {
  }

}
