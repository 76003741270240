import { Component, Input } from "@angular/core";

@Component({
  selector: "app-simple-button-with-icon",
  templateUrl: "./simple-button-with-icon.component.html",
  styleUrls: ["./simple-button-with-icon.component.scss"]
})
export class SimpleButtonWithIconComponent {

  @Input() buttonText: string;
  @Input() buttonIcon: string;
  @Input() disabled: boolean = false;
}
