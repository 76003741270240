/* eslint-disable curly */
/* eslint-disable arrow-body-style */
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, ParamMap, Params, Router } from "@angular/router";
import { NgForm, NgModel } from "@angular/forms";
import { CookieService } from "ngx-cookie-service";
import { GoogleTagManagerHandlerService } from "src/app/services/google-tag-manager.service";
import { AuthService } from "../../../services/auth.service";
import { environment } from "../../../../environments/environment";
import { IntiaroAnalyticsClient } from "../../../services/analytics/intiaro-analytics.service";
import { RedirectService } from "src/app/services/redirect.service";
import { WhiteLabelContextService } from "src/app/services/white-labels/white-label-context.service";
import { WhiteLabelSetupConfiguration } from "src/app/data-models/wl-account-setup/wl-config-data.model";
import { Subscription, filter, take, takeWhile, tap } from "rxjs";
import { WhiteLabelConfigurationService } from "src/app/services/white-labels/white-label-configuration.service";
import { PasswordConditionsComponent } from "../sub-components/password-conditions/password-conditions.component";
import { Db3DApiBackendClient } from "src/app/services/api/db3d-api-backend-client.service";
import { AuthRegistrationData } from "src/app/data-models/auth-response-data.model";
import { NavigationService } from "src/app/services/NavigationService.service";
import { RegexprPatterns } from "src/app/services/RegexprPatterns";

declare var IntiaroAnalyticsManager: any;
@Component({
  selector: "app-registration",
  templateUrl: "./registration.component.html",
  styleUrls: ["../authentication-module.scss"],
})
export class RegistrationComponent implements OnInit, OnDestroy, AfterViewInit {

  public userFormData: { 
    email: string,
    password1: string,
    password2: string,
    termsAccepted: boolean
  } = {
      email: "",
      password1: "",
      password2: "",
      termsAccepted: false
    };

  @ViewChild("registrationForm") registrationForm: NgForm;

  @ViewChild("emailModel") emailModel: NgModel;

  @ViewChild(PasswordConditionsComponent) passwordConditionsComponent: PasswordConditionsComponent;

  public processingCreateAccountRequest: boolean  = false;
  public registrationAttempt: boolean = false;
  public registrationErrorsMessage: string = null;

  public initialized: boolean = false;
  public countriesArray: any;
  public statesArray: any;

  public registrationCode: any;

  private readonly cookieName: string = "db3dregistrationCodeCoockie";


  private whiteLabelSetupConfigurationSubscription: Subscription;

  private isTwoStepRegistration: boolean;

  public nextUrl: string;

  constructor(
    private googleTagManagerHandlerService: GoogleTagManagerHandlerService,
    private intiaroAnalyticsClient: IntiaroAnalyticsClient,
    private cookieService: CookieService,
    private router: Router,
    private whiteLabelContextService: WhiteLabelContextService,
    private authService: AuthService,
    private redirectService: RedirectService,
    private whiteLabelConfigurationService: WhiteLabelConfigurationService,
    private db3DApiBackendClient: Db3DApiBackendClient,
    private changeDetectorRef: ChangeDetectorRef,
    public navigationService: NavigationService,
    public activatedRoute: ActivatedRoute,
  ) {}
  
  ngOnInit(): void {
    this.initialized = true;
    this.assignWhiteLabelSetupConfiguration();
    this.subscribeToQueryParams();
  }
  
  ngAfterViewInit(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.whiteLabelSetupConfigurationSubscription?.unsubscribe();
  }

  private assignWhiteLabelSetupConfiguration(): void {
    const whiteLabelSlug: string = this.whiteLabelContextService.getCurrentWhiteLabelSlug();
  
    this.whiteLabelSetupConfigurationSubscription = this.whiteLabelConfigurationService.whiteLabelSetupConfiguration.pipe(
      tap((wlConfig: WhiteLabelSetupConfiguration) => {
        if (!wlConfig.slug) {
          this.whiteLabelConfigurationService.getWhiteLabelSetupConfiguration(whiteLabelSlug);
        } else {
          this.isTwoStepRegistration = wlConfig.twoStepRegistration;
        }
      })
    ).subscribe();
  }

  private subscribeToQueryParams(): void {
    this.activatedRoute.queryParamMap.subscribe((paramMap: ParamMap) => {
      this.nextUrl = paramMap.get(RedirectService.queryParamKey);
    });
  }

  get emailPatternRegexpr(): RegExp {
    return RegexprPatterns.emailRegxExpr;
  }

  get passwordPatternRegexpr(): RegExp {
    return RegexprPatterns.passwordRegxExpr;
  }

  public checkInputValidity(inputModel:NgModel): boolean {
    return inputModel?.invalid && (inputModel?.dirty || inputModel?.touched);
  }

  public checkPasswordConditions(event: string): void {
    this.passwordConditionsComponent.checkPasswordConditions(event);
  }
  
  private get defaultAfterRegisterPath(): string {
    return this.navigationService.verification;
  }

  private get secondStepAfterRegisterPath(): string {
    return this.navigationService.lastStep;
  }

  public onSubmit(): void {
    if (!this.processingCreateAccountRequest) {
      this.registrationAttempt = true;

      if (this.registrationForm.valid) {
        this.sendRegistrationForm();
      }
    }
  }

  private sendRegistrationForm(): void {

    this.registrationErrorsMessage = null;

    const httpBody: {password: string, email: string, wl_slug: string} = {
      password: this.userFormData.password1,
      email: this.userFormData.email,
      wl_slug: this.whiteLabelContextService.getCurrentWhiteLabelSlug()
    };

    const utmObject = this.getUTMparameters();
    if (utmObject) httpBody["utm"] = utmObject;
  
    if (this.registrationCode) httpBody["registration_code"] = this.registrationCode;

    this.processingCreateAccountRequest = true;


    const nextUrl: string = this.redirectService.getRedirectQueryParams(window.location.search);

    this.db3DApiBackendClient.userRegistration(environment.db3dBackendDomain, httpBody).subscribe({
      next: async(_successResponse: AuthRegistrationData) => {
        this.processingCreateAccountRequest = false;

        this.sendAnalytics();

        this.authService.login(this.userFormData.email, this.userFormData.password1, true).subscribe({
          next: (_value) => {
            
            this.authService.userDataChanged.subscribe((userData) => {

              if (userData) {
                if (this.isTwoStepRegistration) {

                  if(nextUrl) {
                    this.router.navigate([this.secondStepAfterRegisterPath], { queryParams: {next: this.nextUrl } });
                  } else {
                    this.router.navigate([this.secondStepAfterRegisterPath]);
                  }
                } else {
                  if(nextUrl) {
                    this.redirectService.navigateByParam(nextUrl);
                  } else {
                    this.router.navigate([this.defaultAfterRegisterPath]);
                  }
                }
              }
            });

            if (this.isTwoStepRegistration) {
              if(nextUrl) {
                this.router.navigate([this.secondStepAfterRegisterPath], { queryParams: {next: nextUrl } });
              } else {
                this.router.navigate([this.secondStepAfterRegisterPath]);
              }
            } else {
              if(nextUrl) {
                this.redirectService.navigateByParam(nextUrl);
              } else {
                this.router.navigate([this.defaultAfterRegisterPath]);
              }
            }
          }})
        ;
      },
      error: (errorResponse: any) => {
        this.processingCreateAccountRequest = false;
        let registrationErrors: any;
        if (errorResponse.error.errors) {
          registrationErrors = errorResponse.error?.errors?.field_errors || {};
          registrationErrors.non_field_errors = errorResponse.error?.errors?.non_field_errors || [];
        }

        if (registrationErrors?.registration_code) {
          this.registrationCode = null;
          this.cookieService.delete(this.cookieName);
        }

        if (errorResponse.status < 500) {

          if (errorResponse.status === 400) {
            this.registrationErrorsMessage = "User with specified email already exists!";
          }else {
            this.registrationErrorsMessage = "There was something wrong with registration data. Please correct the form and try again.";
          }
        } else {
          this.registrationErrorsMessage = "Something went wrong. Please try again.";
        }
      }
    });

  }

  private getUTMparameters(): string {
    let utmRegistrationParams = null;

    const utmString: string = this.cookieService.get("db3d_utms");
    if (utmString) {
      const utmObj = JSON.parse(utmString);

      utmRegistrationParams = {};

      if (utmObj.utm_source) {
        utmRegistrationParams.campaign_source = utmObj.utm_source;
      }

      if (utmObj.utm_medium) {
        utmRegistrationParams.campaign_medium = utmObj.utm_medium;
      }

      if (utmObj.utm_campaign) {
        utmRegistrationParams.campaign_name = utmObj.utm_campaign;
      }

      if (utmObj.utm_term) {
        utmRegistrationParams.campaign_term = utmObj.utm_term;
      }

      if (utmObj.utm_content) {
        utmRegistrationParams.campaign_content = utmObj.utm_content;
      }
    }
    return utmRegistrationParams;
  }

  private sendAnalytics(): void {    
    this.googleTagManagerHandlerService.sendEvent("registrationFinished");
    this.intiaroAnalyticsClient.sendEvent("registrationFinished");
  }

}
