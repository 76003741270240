// Imports
import {APP_INITIALIZER, ErrorHandler, NgModule} from "@angular/core";
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {Router} from '@angular/router';
import {ClipboardModule} from '@angular/cdk/clipboard';

// Declarations
import {AppComponent} from './app.component';
import {TopNavbarComponent} from './components/top-navbar/top-navbar.component';
import {ProductListComponent} from './components/product-list/product-list.component';
import {FooterComponent} from './components/footer/footer.component';

// Providers
import {MyDesignSaverComponent} from './components/my-design-saver/my-design-saver.component';
import {BreadcrumbsService} from './services/breadcrumb.service';
import {CheckPasswordDirective} from './directives/check-passwords.directive';
import {AccountStatusComponent} from './components/account-status/account-status.component';
import {HeaderComponent} from './components/header/header.component';
import {SharedDesignComponent} from './components/shared-design/shared-design.component';
import {HowtoComponent} from './components/howto/howto.component';
import {AboutComponent} from './components/about/about.component';
import {BlogComponent} from './components/blog/blog.component';
import {PrivacyComponent} from './components/privacy/privacy.component';
import {TermsComponent} from './components/terms/terms.component';
import {TokenInterceptor} from './services/authentication/token-interceptor.service';
import {GlobalSettingsService} from './services/settings/global-settings.service';
import {CookieService} from 'ngx-cookie-service';
import {AdminService} from './services/admin.service';
import {PriceCalculatorService} from "./services/price-calculator.service";

// Custom imports
import {loadSettings} from 'src/app/services/settings/loadSettings';
import {PopupComponent} from './components/popup/popup.component';
import {PopupService} from './services/popup.service';
import {
  RegistrationVerificationComponent
} from './components/registration-verification/registration-verification.component';
import {RegistrationFlowService} from './services/registration-flow.service';

import {Db3dSelectComponent} from './components/db3d-select/db3d-select.component';
import {NotificationsComponent} from './components/notifications/notifications.component';
import {DownloadModelComponent} from './components/download-model/download-model.component';
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import {RegistrationCodeComponent} from './components/registration-code/registration-code.component';
import {LazyLoadImageModule} from 'ng-lazyload-image';

import { LoginComponent } from './components/authentication-module/login/login.component';

import {NgxHotjarModule, NgxHotjarRouterModule} from 'ngx-hotjar';
import {NotificationsPopupComponent} from './components/notifications-popup/notifications-popup.component';
import {ImageExportPopupComponent} from './components/image-export-popup/image-export-popup.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {SingUpLogInBarComponent} from './components/sing-up-log-in-bar/sing-up-log-in-bar.component';

import {LottieModule} from 'ngx-lottie';
import player from 'lottie-web';
import {QrCodeModule} from 'ng-qrcode';

// Sentry
import * as Sentry from "@sentry/angular";

import {environment} from 'src/environments/environment';
import {CheckFullNameDirective} from './directives/full-name.validator.directive';
import {RequireOneInputFieldDirective} from './directives/require-one-input-field.directive';
import {RequestFurnitureAdComponent} from './components/request-furniture-ad/request-furniture-ad.component';
import {TwoStatesButtonComponent} from './components/two-states-button/two-states-button.component';
import { RegistrationComponent } from './components/authentication-module/registration/registration.component';
import {CountDownComponent} from './components/registration-verification/count-down/count-down.component';

import {
  ProductDesignConfigurator360Component
} from './components/product-design-configurator360/product-design-configurator360.component';
import {ProductDesignDemoComponent} from './components/product-design-demo/product-design-demo.component';
import {NavbarService} from './services/navbar.service';
import {WhiteLabelConfigurationService} from './services/white-labels/white-label-configuration.service';
import {WhiteLabelContextService} from './services/white-labels/white-label-context.service';
import {
  FullFunctionalityDialogComponent
} from './components/product-design-demo/full-functionality-dialog/full-functionality-dialog.component';
import {FooterService} from './services/footer.service';
import {GettingStartedComponent} from './components/getting-started/getting-started.component';
import {ContactComponent} from './components/contact/contact.component';

import {MyModelsComponent} from './components/my-models/my-models.component';
import {NotificationsCountComponent} from './components/top-navbar/notifications-count/notifications-count.component';
import {
  NoModelsGeneratedInformationPanelComponent
} from './components/my-models/no-models-generated-information-panel/no-models-generated-information-panel.component';
import {MyModelsListItemComponent} from './components/my-models/my-models-list-item/my-models-list-item.component';
import {
  MyModelsTableHeaderComponent
} from './components/my-models/my-models-table-header/my-models-table-header.component';
import {
  MyModelsNavItemButtonComponent
} from './components/top-navbar/my-models-nav-item-button/my-models-nav-item-button.component';
import {BrandsFilterComponent} from './components/product-list/brands-filter/brands-filter.component';
import { ProductsNotFoundComponent } from './components/product-list/products-not-found/products-not-found.component';
import {ProductDesignPreviewComponent} from "./components/product-design-preview/product-design-preview.component";
import {QrCodeComponent} from './components/qr-code/qr-code.component';
import {ProductDesignComponent} from './components/product-design/product-design.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {UserDetailsEditComponent} from "./components/user-details-edit/user-details-edit.component";
import { ProductDesignEditComponent } from './components/product-design-edit/product-design-edit.component';
import { ProductConfigComponent } from './components/product-config/product-config.component';
import { ProductDetailsComponent } from './components/product-details/product-details.component';
import { ProductConfigConfigurator360Component } from './components/product-config-configurator360/product-config-configurator360.component';
import { SwitchViewsTabsComponent } from './components/switch-views-tabs/switch-views-tabs.component';
import { TabSwitchComponent } from './components/switch-views-tabs/tab-switch.component';
import { SaveButtonComponent } from './components/save-button/save-button.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { OtherDesignsComponent } from "./components/other-designs/other-designs.component";
import { FullscreenDesignSaverComponent } from './components/fullscreen-design-saver/fullscreen-design-saver.component';
import { CopyLinkButtonComponent } from './components/copy-link-button/copy-link-button.component';
import { AdminViewComponent } from './components/admin-view/admin-view.component';
import { UsersListComponent } from './components/users-list/users-list.component';
import { UserDetailsComponent } from './components/user-details/user-details.component';
import { LogoUploadComponent } from './components/account-setup-wrapper/logo-upload/logo-upload.component';
import { PdfTemplateComponent } from './components/account-setup-wrapper/pdf-template/pdf-template.component';
import { UserRoleSelectComponent } from './components/user-role-select/user-role-select.component';
import { UsersListSearchComponent } from "./components/users-list/users-list-search/users-list-search.component";
import { ChevronButtonComponent } from './components/chevron-button/chevron-button.component';
import { DualStateButtonComponent } from './components/dual-state-button/dual-state-button.component';
import { DashIfEmptyPipe } from "./pipes/dash-if-empty.pipe";
import { AccountSetupWrapperComponent } from "./components/account-setup-wrapper/account-setup-wrapper.component";
import { TruncatePipe } from "./pipes/truncate.pipe";
import { PricesControlComponent } from "./components/account-setup-wrapper/prices-control/prices-control.component";
import { CheckboxWithLabelComponent } from "./components/account-setup-wrapper/checkbox-with-label/checkbox-with-label.component";
import { ColorSchemeService } from  "./services/colors-scheme.service";
import { AdjustColorSchemeComponent } from "./components/account-setup-wrapper/adjust-color-scheme/adjust-color-scheme.component";
import { DynamicSvgComponent } from './components/dynamic-svg/dynamic-svg.component';
import { PaginatorComponent } from "./components/users-list/paginator/paginator.component";
import { BrandsVisibilityFormComponent } from "./components/account-setup-wrapper/brands-visibility-form/brands-visibility-form.component";
import { ConfigurationtDetailsComponent } from "./components/configuration-details/configuration-details.component";
import { CurrencySelectorComponent } from './components/currency-selector/currency-selector.component';
import { CurrencyService } from "./services/currencyService";
import {VerifiedUserGuard} from "./services/guards/verifiedUserGuard";
import {LoggedInGuard} from "./services/guards/loggedInGuard";
import {NotLoggedInGuard} from "./services/guards/notLoggedInGuard";
import {UnverifiedUserGuard} from "./services/guards/unverifiedUserGuard";
import {GenericPermissionGuard} from "./services/guards/genericPermissionGuard";
import { AuthenticationModuleContainerComponent } from "./components/authentication-module/sub-components/authentication-module-container/authentication-module-container.component";
import { WlLogoComponent } from "./components/authentication-module/sub-components/wl-logo/wl-logo.component";
import { FormFieldErrorsComponent } from "./components/authentication-module/sub-components/form-field-errors/form-field-errors.component";
import { PasswordInputWrapperComponent } from "./components/authentication-module/sub-components/password-input-wrapper/password-input-wrapper.component";
import { FormCheckboxWrapperComponent } from "./components/authentication-module/sub-components/form-checkbox-wrapper/form-checkbox-wrapper.component";
import { ProductDesignSaverService } from "./services/product-savers/product-design-saver.service";
import { ProductActionBarComponent } from "./components/product-action-bar/product-action-bar.component";
import { PriceHandlerComponent } from "./components/price-handler/price-handler.component";
import { SimpleSelectComponent } from "./components/simple-select/simple-select.component";
import { SimpleSelectOptionComponent } from "./components/simple-select/simple-select-option/simple-select-option.component";
import { MyProfileComponent } from "./components/my-profile/my-profile.component";
import { BasicInfoEditComponent } from "./components/my-profile/basic-info/basic-info-edit/basic-info-edit.component";
import { BasicInfoDisplayComponent } from "./components/my-profile/basic-info/basic-info-display/basic-info-display.component";
import { MyProfileButtonComponent } from "./components/my-profile/my-profile-button/my-profile-button.component";
import { ToastService } from "./services/toast.service";
import { ToastBaseComponent } from "./components/toast/toast-base.component";
import { ToastErrorComponent } from "./components/toast/toast-error/toast-error.component";
import { ToastSuccessComponent } from "./components/toast/toast-success/toast-success.component";
import { ToastWarningComponent } from "./components/toast/toast-warning/toast-warning.component";
import { MyDesignPreviewComponent } from "./components/my-design-preview/my-design-preview.component";
import { DesignConfigurationComponent } from "./components/my-design-preview/design-configuration/design-configuration.component";
import { ContactBoxComponent } from "./components/my-design-preview/contact-box/contact-box.component";
import { NavbarOnlyLogoComponent } from "./components/top-navbar/navbar-only-logo/navbar-only-logo.component";
import { SimpleButtonWithIconComponent } from "./components/simple-button-with-icon/simple-button-with-icon.component";
import { NonFieldFormErrorsComponent } from "./components/non-field-form-errors/non-field-form-errors.component";
import { ShareDialogComponent } from "./components/share-dialog/share-dialog.component";
import { DynamicPopupComponent } from "./components/dynamic-popup/dynamic-popup.component";
import { DyanmicPopupService } from "./services/dynamic-popup.service";
import { DropdownListBtnComponent } from "./components/top-navbar/dropdown-list-btn/dropdown-list-btn.component";
import { RegistrationLastStepComponent } from "./components/authentication-module/registration-last-step/registration-last-step.component";
import { ResetPasswordComponent } from "./components/authentication-module/reset-password/reset-password.component";
import { PasswordConditionsComponent } from "./components/authentication-module/sub-components/password-conditions/password-conditions.component";
import { IntervalService } from "./services/auth.service";
import { NavigationService, RaoutesRegistry } from "./services/NavigationService.service";
import { VerificationComponent } from "./components/authentication-module/verification/verification.component";
import { ShareDialogFirstStepComponent } from "./components/share-dialog/share-dialog-first-step/share-dialog-first-step.component";
import { IntiaroQrCodeTemplateService } from "./services/integrationQrCode.service";
import { ProfilePictureDisplayComponent } from "./components/my-profile/profile-picture/profile-picture-display/profile-picture-display.component";
import { ProfilePictureEditComponent } from "./components/my-profile/profile-picture/profile-picture-edit/profile-picture-edit.component";
import { AvatarService } from "./services/avatar.service";
import { ChooseFileButtonComponent } from "./components/share-dialog/choose-file-button/choose-file-button.component";


// Only enable sentry on production environment
// if (environment.production) {
//   console.log('Production environment detected. Enabling sentry.');
//
//   Sentry.init({
//     dsn: 'https://75366f1ce7d340ac889e238486d61ee9@sentry.intiaro.com/4',
//     environment: environment.production ? 'prod' : 'dev',
//     release: `designers-board-3d (2.1.0)`,
//     integrations: [
//       new Integrations.BrowserTracing({
//         tracingOrigins: ['localhost', 'https://app.db3d.com/'],
//         routingInstrumentation: Sentry.routingInstrumentation,
//       }),
//     ],
//
//     // We recommend adjusting this value in production, or using tracesSampler
//     // for finer control
//     tracesSampleRate: 1.0,
//   });
// }

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    TopNavbarComponent,
    ProductsNotFoundComponent,
    LoginComponent,
    ProductListComponent,
    FooterComponent,
    FormFieldErrorsComponent,
    MyDesignSaverComponent,
    CheckPasswordDirective,
    CheckFullNameDirective,
    AccountStatusComponent,
    HeaderComponent,
    SharedDesignComponent,
    HowtoComponent,
    AboutComponent,
    BlogComponent,
    PrivacyComponent,
    TermsComponent,
    PopupComponent,
    RegistrationVerificationComponent,
    VerificationComponent,
    Db3dSelectComponent,
    NotificationsComponent,
    DownloadModelComponent,
    RegistrationCodeComponent,
    NotificationsPopupComponent,
    ImageExportPopupComponent,
    SingUpLogInBarComponent,
    RequireOneInputFieldDirective,
    RequestFurnitureAdComponent,
    TwoStatesButtonComponent,
    RegistrationComponent,
    CountDownComponent,
    ProductDesignConfigurator360Component,
    ProductDesignDemoComponent,
    FullFunctionalityDialogComponent,
    GettingStartedComponent,
    ContactComponent,
    MyModelsComponent,
    NotificationsCountComponent,
    NoModelsGeneratedInformationPanelComponent,
    MyModelsListItemComponent,
    MyModelsTableHeaderComponent,
    MyModelsNavItemButtonComponent,
    BrandsFilterComponent,
    ProductDesignPreviewComponent,
    QrCodeComponent,
    ProductDesignComponent,
    ProductDesignEditComponent,
    ProductConfigComponent,
    ProductDetailsComponent,
    ProductConfigConfigurator360Component,
    SwitchViewsTabsComponent,
    TabSwitchComponent,
    SaveButtonComponent,
    PageNotFoundComponent,
    OtherDesignsComponent,
    FullscreenDesignSaverComponent,
    CopyLinkButtonComponent,
    AdminViewComponent,
    UsersListComponent,
    UserDetailsComponent,
    UserDetailsEditComponent,
    LogoUploadComponent,
    PdfTemplateComponent,
    UserRoleSelectComponent,
    UsersListSearchComponent,
    ChevronButtonComponent,
    DualStateButtonComponent,
    DashIfEmptyPipe,
    AccountSetupWrapperComponent,
    TruncatePipe,
    PricesControlComponent,
    CheckboxWithLabelComponent,
    DynamicSvgComponent,
    AdjustColorSchemeComponent,
    PaginatorComponent,
    BrandsVisibilityFormComponent,
    ConfigurationtDetailsComponent,
    CurrencySelectorComponent,
    AuthenticationModuleContainerComponent,
    WlLogoComponent,
    FormFieldErrorsComponent,
    PasswordInputWrapperComponent,
    FormCheckboxWrapperComponent,
    PriceHandlerComponent,
    ProductActionBarComponent,
    SimpleSelectComponent,
    SimpleSelectOptionComponent,
    MyProfileComponent,
    BasicInfoDisplayComponent,
    BasicInfoEditComponent,
    MyProfileButtonComponent,
    ToastBaseComponent,
    ToastErrorComponent,
    ToastSuccessComponent,
    ToastWarningComponent,
    RegistrationLastStepComponent,
    ResetPasswordComponent,
    NonFieldFormErrorsComponent,
    PasswordConditionsComponent,
    ShareDialogComponent,
    DynamicPopupComponent,
    MyDesignPreviewComponent,
    DesignConfigurationComponent,
    ContactBoxComponent,
    NavbarOnlyLogoComponent,
    SimpleButtonWithIconComponent,
    MyProfileComponent,
    DropdownListBtnComponent,
    ShareDialogFirstStepComponent,
    ProfilePictureDisplayComponent,
    ProfilePictureEditComponent,
    ChooseFileButtonComponent
  ],
  imports: [
    BrowserModule,
    NoopAnimationsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatDialogModule,
    MatMenuModule,
    MatIconModule,
    LazyLoadImageModule,
    NgxHotjarModule.forRoot("2138268"),
    NgxHotjarRouterModule,
    InfiniteScrollModule,
    ClipboardModule,
    LottieModule.forRoot({ player: playerFactory }),
    QrCodeModule //https://www.npmjs.com/package/ng-qrcode
  ],
  providers: [
    { provide: "googleTagManagerId", useValue: environment.googleTagManagerId },
    { provide: APP_INITIALIZER,
      useFactory: loadSettings,
      deps: [
        HttpClient,
        GlobalSettingsService
      ],
      multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
        logErrors: true
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    UnverifiedUserGuard,
    VerifiedUserGuard,
    GenericPermissionGuard,
    LoggedInGuard,
    NotLoggedInGuard,
    BreadcrumbsService,
    RegistrationFlowService,
    PopupService,
    CookieService,
    NavbarService,
    WhiteLabelConfigurationService,
    WhiteLabelContextService,
    FooterService,
    SaveButtonComponent,
    CopyLinkButtonComponent,
    AdminService,
    TopNavbarComponent,
    ColorSchemeService,
    PdfTemplateComponent,
    PriceCalculatorService,
    CurrencyService,
    ProductDesignSaverService,
    ToastService,
    IntervalService,
    RaoutesRegistry,
    NavigationService,
    DyanmicPopupService,
    IntiaroQrCodeTemplateService,
    AvatarService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
