/* eslint-disable arrow-body-style */
/* eslint-disable space-before-blocks */
/* eslint-disable curly */
import { Injectable } from "@angular/core";
import { catchError, tap } from "rxjs/operators";
import { throwError, BehaviorSubject, Observable } from "rxjs";
import { Router } from "@angular/router";
import { User, UserService } from "./authentication/user.service";
import { GlobalSettingsService } from "./settings/global-settings.service";
import { environment } from "../../environments/environment";
import { IntiaroAnalyticsClient } from "./analytics/intiaro-analytics.service";
import { WhiteLabelContextService } from "./white-labels/white-label-context.service";
import { Db3DApiBackendClient } from "./api/db3d-api-backend-client.service";
import { NavigationService } from "./NavigationService.service";

export class UserResponseData {
  public full_name: string;
  public status: string;
  public trial_expiration_date: string;
  public phone_number: string;
  public portfolio_url: string;
  public company: string;
}

@Injectable({ providedIn: "root" })
export class AuthService {
  public userKey: string;
  private readonly userKeyPrefix: string = "designerBoard3DUserData";
  private tokenExpirationTimer: any;
  public loggedIn: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public userDataChanged: BehaviorSubject<User> = new BehaviorSubject(null);

  constructor(
      private router: Router,
      private globalSettingsService: GlobalSettingsService,
      private intiaroAnalyticsClient: IntiaroAnalyticsClient,
      private userService: UserService,
      private whiteLabelContextService: WhiteLabelContextService,
      private db3DApiBackendClient: Db3DApiBackendClient,
      private intervalService: IntervalService,
      public navigationService: NavigationService
  ) {
    this.initialize();
  }

  public initialize(): void {
    this.buildUserCookieKey();
    if (this.userService.getCurrentUser()) {
      this.updateUserInfo();
    }
    this.intervalService.startInterval(async() => {
      if (this.userService.getCurrentUser()){
        await this.updateUserInfo();
      }
    }, 3600000);
  }

  buildUserCookieKey() {
    this.userKey = `${this.userKeyPrefix}_${this.globalSettingsService.environmentId}`;
  }

  public loginWithToken(token: string){
    this.intiaroAnalyticsClient.sendEvent("loginAttempt", { Mode: "magicLink" });

    return this.db3DApiBackendClient.userLoginWithToken(this.globalSettingsService.loginDomain, { token: token}).pipe(
      catchError((errorResponse) => {
        this.intiaroAnalyticsClient.sendEvent("loginFinished", { Mode: "magicLink", Status: "fail" });
        return this.handleFailLoginResponse(errorResponse);
      }),
      // Loggin user in
      tap( (responseData) => {
        this.userService.setCurrentUser(new User(responseData));
        this.userDataChanged.next(this.userService.getCurrentUser());
        this.loggedIn.next(true);

        this.intiaroAnalyticsClient.sendEvent("loginFinished", { Mode: "magicLink", Status: "success" });
        this.userService.getCurrentUser().autologin = true;
        localStorage.setItem(this.userKey, JSON.stringify(this.userService.getCurrentUser()));
        // this.autoLogout(responseData.valid * 1000);
      })
    );
  }

  public login(email: string, password: string, autologin: boolean): Observable<any> {
    this.intiaroAnalyticsClient.sendEvent("loginAttempt", { Mode: "manual" });

    const httpBody: {email: string, password: string, api: string, app: string, wl_slug: string} = {
      email: email, password: password, api: "4.3", app: "DB3D", wl_slug: this.whiteLabelContextService.getCurrentWhiteLabelSlug()
    };
    return this.db3DApiBackendClient.userLogin(this.globalSettingsService.db3dBackendDomain, httpBody).pipe(
      catchError((errorResponse) => {
        this.intiaroAnalyticsClient.sendEvent("loginFinished", { Mode: "manual", Status: "fail" });
        return this.handleFailLoginResponse(errorResponse);
      }),
      // Loggin user in
      tap((responseData) => {
        const userObject = new User(responseData, email);
        this.userService.setCurrentUser(userObject);

        this.userDataChanged.next(this.userService.getCurrentUser());
        this.loggedIn.next(true);

        this.intiaroAnalyticsClient.sendEvent("loginFinished", { Mode: "manual", Status: "success" });
        this.userService.getCurrentUser().autologin = autologin;
        localStorage.setItem(this.userKey, JSON.stringify(this.userService.getCurrentUser()));
        // this.autoLogout(responseData.valid * 1000);
      })
    );
  }

  public autoLogin(): void {
    this.intiaroAnalyticsClient.sendEvent("loginAttempt", { Mode: "auto" });

    const savedUser = JSON.parse(localStorage.getItem(this.userKey));
    this.userService.setCurrentUser(Object.assign(new User(), savedUser));

    if (!this.userService.getCurrentUser() || !this.userService.getCurrentUser().db3dToken || !savedUser.autologin) { // has no valid token
      this.userService.setCurrentUser(null);
      this.userDataChanged.next(this.userService.getCurrentUser());
      this.loggedIn.next(false);

      this.intiaroAnalyticsClient.sendEvent("loginFinished", { Mode: "auto", Status: "fail" });
    }else{ // User has valid token and can be auto logged in
      this.loggedIn.next(true);
      this.userDataChanged.next(this.userService.getCurrentUser());

      this.intiaroAnalyticsClient.sendEvent("loginFinished", { Mode: "auto", Status: "success" });
      // Start auto logout timer
      const timeout = new Date(this.userService.getCurrentUser().tokenExpDate).getTime() - new Date().getTime();
      // this.autoLogout(timeout);
    }
  }

  public logout(navigate:any[] = [this.navigationService.login]): void {
    this.userService.setCurrentUser(null);
    this.loggedIn.next(false);
    this.userDataChanged.next(this.userService.getCurrentUser());

    this.intiaroAnalyticsClient.sendEvent("logout");
    this.router.navigate(navigate);
    localStorage.removeItem(this.userKey);

    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
    }
  }

  public autoLogout(miliseconds: number): void {
    this.tokenExpirationTimer = setTimeout(() => {
      this.logout();
    }, miliseconds);
  }

  async updateUserInfo(): Promise<void> {
    this.db3DApiBackendClient.updateUserInfo(environment.db3dBackendDomain, this.userService.getCurrentUser().designerId).subscribe({
      next:(_response: UserResponseData ) => {
        this.userService.getCurrentUser().accountStatus = null;
        localStorage.setItem(this.userKey, JSON.stringify(this.userService.getCurrentUser()));
        this.userDataChanged.next(this.userService.getCurrentUser());
      },
      error:(error) => {
        if (error.status === 403) {
          this.logout();
        }else{
          // eslint-disable-next-line no-console
          console.log(error);
        }
      },

    });
  }

  private handleFailLoginResponse(errorResponse: any): Observable<never> {
    let errorMsg: string = "An unknown error occured";

    switch (errorResponse.error.error_type){
    case "IncorrectAuthData":
      errorMsg = "Incorrect authentication data";
      break;

    case "InactiveAccount":
      errorMsg = "This account is inactive";
      break;

    case "DeprecatedApiVersion":
      errorMsg = "Deprecated API version";
      break;

    case "BadRequestData":
      errorMsg = "Incorrect endpoint request";
      break;
    }

    return throwError(() => errorResponse);
  }
}

export class IntervalService {
  public startInterval(callback: () => void, interval: number) {
    return setInterval(callback, interval);
  }

  public stopInterval(intervalId: any) {
    clearInterval(intervalId);
  }
}
