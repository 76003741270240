/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
import { Component, ElementRef, Input, OnInit, Renderer2 } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ColorSchemeService, Colors } from "src/app/services/colors-scheme.service";

@Component({
  selector: "app-dynamic-svg",
  templateUrl: "./dynamic-svg.component.html",
  styleUrls: ["./dynamic-svg.component.scss"]
})
export class DynamicSvgComponent implements OnInit {

  @Input() private svgPath: string; 
  @Input() private colorVarName: string = "secondary-button-color";
  @Input() private changePathColor: boolean = true;

  constructor(
    private http: HttpClient,
    private el: ElementRef,
    private renderer: Renderer2,
    private colorSchemeService: ColorSchemeService
  ) {}

  ngOnInit() {
    this.colorSchemeService.colorsSubject.subscribe((colors: Colors) => {
      this.loadSVG(this.colorSchemeService.getColorAttribute(this.colorVarName, colors)?.variable_value);
    });

  }

  private loadSVG(color:string) {
    this.http.get(this.svgPath, { responseType: "text" }).subscribe((dataSvgContent: string) => {
      const svg = this.createSvgObjectWithColor(dataSvgContent, color);
      if (this.el.nativeElement.querySelector("svg")) 
        this.renderer.removeChild(this.el.nativeElement, this.el.nativeElement.querySelector("svg")); 
      
      this.renderer.appendChild(this.el.nativeElement, svg);
    });
  }

  private createSvgObjectWithColor(svgData: string, color:string) {
    const div = this.renderer.createElement("div");
    div.innerHTML = svgData;
    const svg = div.querySelector("svg");
    svg.setAttribute("fill", color);

    if(this.changePathColor) {
      const paths = svg.querySelectorAll("path");
      paths.forEach((element: Element) => element.setAttribute("fill", color));  
    }
 
    const rects = svg.querySelectorAll("rect");
    rects.forEach((element:Element) => element.setAttribute("fill", color));
 
    return svg;
  }

}