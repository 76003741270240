/* eslint-disable curly */
/* eslint-disable arrow-body-style */
import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { Router } from "@angular/router";
import { NgForm } from "@angular/forms";
import { GlobalSettingsService } from "src/app/services/settings/global-settings.service";
import { PopupService } from "src/app/services/popup.service";
import { ProductDesign } from "src/app/data-models/product-design";
import { ProductDesignPayload, ProductDesignSaverService } from "src/app/services/product-savers/product-design-saver.service";
import { NavigationService } from "src/app/services/NavigationService.service";


enum PopupModes {
  Start = "start",
  Save = "save",
  Overwrite = "overwrite",
  Error = "error"
}

@Component({
  selector: "app-my-design-saver",
  templateUrl: "./my-design-saver.component.html",
  styleUrls: ["./my-design-saver.component.scss"]
})
export class MyDesignSaverComponent implements OnInit {

  @Input() private popupService: PopupService;
  @Input() public productDesign: ProductDesign;
  @Input() public customConfigurationId: string;
  @Input() public configuration: any;
  @Input() public productImageUrl: string;
  
  @ViewChild("myDesignSaveForm") myDesignSaveForm: NgForm;

  get popupModes(): typeof PopupModes {
    return PopupModes;
  }

  public designName: string = "";

  public opened: boolean;  
  public popupMode: PopupModes = PopupModes.Start;
  public savingProductDesignInProgress = false;
  private savedDesignId: string;

  public title: string;
  public text: string;

  constructor(
    private router: Router,
    private globalSettingsService: GlobalSettingsService,
    private productDesignSaverService: ProductDesignSaverService,
    public navigationService: NavigationService
  ) {}

  ngOnInit(): void {
    this.title = "MY DESIGNS";
    this.text = "Would you like to overwrite existing design or save it as new design?";
  }

  public hidePopup(): void {
    this.popupService.hidePopup();
    if (this.myDesignSaveForm) 
      this.myDesignSaveForm.reset();    
  }

  private getPayload(): ProductDesignPayload {
    const obj: ProductDesignPayload = {
      productDetails: this.productDesign,
      configuration: this.configuration,
      customConfigurationId: this.customConfigurationId,
      productImageUrl: this.productImageUrl,
      designName: this.designName || this.productDesign.name,
      productId: this.productDesign.productId,
      productName: this.productDesign.productName,
    };
    if (this.productDesign.pim_context) obj.pimContext = this.productDesign.pim_context;
    return obj;
  }

  public async saveMyDesign(): Promise<void> {

    this.savingProductDesignInProgress = true;

    const successCallback = (value: any) => {
      this.savingProductDesignInProgress = false;
      this.showSavedSuccessfullyPopup(value.id);
    };

    const failCallback = (errorResponse: any) => {
      this.savingProductDesignInProgress = false;
      if (errorResponse.status === 409) {
        this.productDesign.id = errorResponse.error.my_design_id;
        this.showNameConflictPopup();
      } else if (errorResponse.status === 400 && errorResponse.error.configuration) {
        this.showEmptySceneErrorPopup();
      } else this.showSaveErrorPopup();
    };

    this.productDesignSaverService.saveMyDesign(
      successCallback, 
      failCallback,
      this.globalSettingsService.db3dBackendDomain,
      this.getPayload()
    );
  }

  public overwriteExistingDesign(): void {
    this.savingProductDesignInProgress = true;

    const successCallback = (value) => {
      this.savingProductDesignInProgress = false;
      this.showSavedSuccessfullyPopup(value.id);
    };

    const failCallback = (errorResponse) => {
      this.savingProductDesignInProgress = false;
      if (errorResponse.status === 400 && errorResponse.error.configuration) this.showEmptySceneErrorPopup();
      else this.showSaveErrorPopup();
    };

    this.productDesignSaverService.overwriteExistingDesign(
      successCallback, failCallback,
      this.globalSettingsService.db3dBackendDomain,
      this.getPayload(),
      this.productDesign.id
    );
  }

  private showSaveErrorPopup(popupText = "Could not save this design. Please try again later."): void {
    this.hidePopup();
    const options = {
      title: "MY DESIGNS",
      text: popupText,
    };

    setTimeout(() => {
      this.popupService.showPopup(options);
    }, 500);
  }

  public showSaveNewDesignPopup(): void {
    this.popupMode = PopupModes.Save;
    this.title = "Save as:";
    this.text = null;
  }

  public showSavedDesign(): void {
    this.hidePopup();
    this.router.navigate([this.navigationService.productDesign(this.savedDesignId)]);
  }

  private showSavedSuccessfullyPopup(savedDesignId: string): void {
    this.savedDesignId = savedDesignId;
    this.popupMode = PopupModes.Overwrite;
    this.title = "Success!";
    this.text = "Your design was saved successfully. You can see it by clicking the button below.";
  }

  private showNameConflictPopup(): void {
    this.popupMode = PopupModes.Start;
    this.title = "MY DESIGNS",
    this.text = "Design with this name already exists in your library? Would you like to overwrite it or save it as a new one with different name?";
  }

  private showEmptySceneErrorPopup(): void {
    this.popupMode = PopupModes.Error;
    this.title = "Saving My Design Failed";
    this.text = "Your configuration cannot be empty. Please select at least 1 section and confirm by clicking \"Finish building\" button.";
  }

  activeRoute(routename: string): boolean {
    return this.router.url.indexOf(routename) > -1;
  }

}