import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-save-button',
  templateUrl: './save-button.component.html',
  styleUrls: ['./save-button.component.scss']
})
export class SaveButtonComponent {

  @Input() contentBtn = 'Save';
  @Input() disabled = false;

  constructor() {}

  public setDisabledAttribute(value: boolean): void {
    this.disabled = value;
  }

  ngOnDestroy(): void {
    this.disabled = false;
  }
}
