import { Injectable } from '@angular/core';
import { BehaviorSubject} from 'rxjs';
import { environment } from 'src/environments/environment';
import { AdminMenuItem, AdminMenuStructure } from '../components/admin-view/admin-view.component';

export type menuItem = {
  id: number;
  title: string;
  active: boolean;
  iconPath: string;
  navigateTo: string;
}

@Injectable({ providedIn: "root" })
export class AdminService {
  public environment = environment;
  public menuStructure: AdminMenuStructure;
  public currentActiveItem: BehaviorSubject<AdminMenuItem>;

  constructor() {
    this.initializeMenu();
  }

  private initializeMenu(): AdminMenuStructure {
    this.menuStructure = new AdminMenuStructure();
    const firstMenuItem = this.menuStructure.getMenuItems()[0];
    this.currentActiveItem = new BehaviorSubject(firstMenuItem);
    this.currentActiveItem.value.setActive();
    return this.menuStructure;
  }

  public setActiveTab(menuItemSlug: string) {
    this.currentActiveItem.value?.setNotActive();
    const menuItem = this.menuStructure.getMenuItems().find((x) => { return x.slug === menuItemSlug; });
    menuItem?.setActive();
    this.currentActiveItem.next(menuItem);
  }
}
