import { Component, Input } from "@angular/core";
import { NavigationService } from "src/app/services/NavigationService.service";

@Component({
  selector: "app-sing-up-log-in-bar",
  templateUrl: "./sing-up-log-in-bar.component.html",
  styleUrls: ["./sing-up-log-in-bar.component.css"]
})
export class SingUpLogInBarComponent  {

  @Input() template: string;

  constructor(
    public navigationService: NavigationService
  ) {}

}
