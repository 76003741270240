/* eslint-disable arrow-body-style */
import {UserPermissions, UserService} from "../../authentication/user.service";
import {ActivatedRouteSnapshot, Router, UrlTree} from "@angular/router";
import {IGuardMixin} from "./IGuardMixin";
import { NavigationService } from "../../NavigationService.service";
export class VerifiedGuardMixin implements IGuardMixin {
  constructor(protected userService: UserService) {}

  public getRedirectUrlTree(router: Router, _route: ActivatedRouteSnapshot): UrlTree {
    return router.createUrlTree([NavigationService.create().verification]);
  }

  public evaluate(_route: ActivatedRouteSnapshot): boolean {
    return this.userService.getCurrentUser().permissions.includes(UserPermissions.Designer);
  }
}