import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { BreadcrumbsService } from 'src/app/services/breadcrumb.service';
import { IBreadCrumb } from 'src/app/data-models/Ibreadcrumb.interface';
import { Subscription } from 'rxjs';
import { GlobalSettingsService } from 'src/app/services/settings/global-settings.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

  private _routerSub = Subscription.EMPTY;

  private loggedIn = false;
  private breadcrumbs: Array<IBreadCrumb> = new Array<IBreadCrumb>();
  public pageName: string;
  private homepageLabel = 'Product List';

  constructor(
    private authService: AuthService,
    private globalSettingsService: GlobalSettingsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private breadcrumbsService : BreadcrumbsService
  )
  {
    this._routerSub = this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationStart)
      {
        this.pageName ='';
        this.breadcrumbs = new Array<IBreadCrumb>();
      }
    });

    this.breadcrumbsService.breadcrumbs.subscribe((breadcrumbs) =>
    {
      if(breadcrumbs)
      {
        this.pageName = breadcrumbs[breadcrumbs.length-1].label;

        this.breadcrumbs = new Array<IBreadCrumb>();
        if(breadcrumbs[0].label != this.homepageLabel)
        {
          this.breadcrumbs.push({
            label: "Product List",
            path: "/product-list",
            active: false
          });
        }

        this.breadcrumbs = this.breadcrumbs.concat(breadcrumbs);
      }
    });
  }

  ngOnInit() {
  }

  ngOnDestroy(){
    this._routerSub.unsubscribe();
  }

}
