import { ComponentRef, Injectable, ViewContainerRef } from "@angular/core";
import { ProductConfigConfigurator360Component } from "../components/product-config-configurator360/product-config-configurator360.component";
import { ProductDesignConfigurator360Component } from "../components/product-design-configurator360/product-design-configurator360.component";

export type Instance360Component = ProductConfigConfigurator360Component | ProductDesignConfigurator360Component;

@Injectable({ providedIn: "root" })
export class Product360InstancesService {

  constructor() {}

  public createProductDesignConfigurator360ComponentRef(
    customConfigId:string,
    brandId: string,
    context: string,
    viewContainerRef:ViewContainerRef,
    saveAndRenderStrategy?: string
  ): ComponentRef<ProductDesignConfigurator360Component> {
    const ref = viewContainerRef.createComponent(ProductDesignConfigurator360Component);
    ref.setInput("brandId", brandId);
    ref.setInput("configurationId", customConfigId);
    ref.setInput("showMenu", true);
    if (context) ref.setInput("context", context);
    if (saveAndRenderStrategy) ref.setInput("saveAndRenderStrategy", saveAndRenderStrategy);
    return ref;
  }

  public createProductConfigConfigurator360ComponentRef(
    customConfigId:string,
    brandId: string,
    context: string,
    viewContainerRef:ViewContainerRef,
    domain:string,
    elementId:string,
    saveAndRenderStrategy?: string
  ): ComponentRef<ProductConfigConfigurator360Component> {
    const ref = viewContainerRef.createComponent(ProductConfigConfigurator360Component);
    ref.location.nativeElement.id = elementId;
    ref.setInput("brandId", brandId);
    ref.setInput("configurationId", customConfigId);
    ref.setInput("domainTemplate", domain);
    if (context) ref.setInput("context", context);
    if (saveAndRenderStrategy) ref.setInput("saveAndRenderStrategy", saveAndRenderStrategy);
    return ref;
  }

}
