import { Component } from "@angular/core";

@Component({
  selector: "app-products-not-found",
  templateUrl: "./products-not-found.html",
  styleUrls: ["./products-not-found.component.scss"]
})

export class ProductsNotFoundComponent {

  constructor() {}

}
