import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  ViewContainerRef,
  ComponentRef
} from "@angular/core";
import { IDyanmicPopupContent, DyanmicPopupService } from "src/app/services/dynamic-popup.service";

@Component({
  selector: "app-dynamic-popup",
  templateUrl: "./dynamic-popup.component.html",
  styleUrls: ["./dynamic-popup.component.scss"]
})
export class DynamicPopupComponent implements OnInit {
  @ViewChild("htmlContainer", { static: false }) container: ElementRef;
  @ViewChild("ngComponent", { read: ElementRef }) private ngComponent: ElementRef;

  public opened: boolean;
  public content: IDyanmicPopupContent;

  constructor(
    private dyanmicPopupService: DyanmicPopupService,
    private changeDetector: ChangeDetectorRef,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.subscribeToDynamicPopupContentService();
  }

  private subscribeToDynamicPopupContentService(): void {
    this.dyanmicPopupService.popupContent.subscribe(
      (popupContent: IDyanmicPopupContent) => {

        if (popupContent) {
          this.content = popupContent;
      
          if (this.content.ngDynamicComponent) {
            
            const compRef:ComponentRef<any> = this.viewContainerRef.createComponent(this.content.ngDynamicComponent.component);

            Object.entries(this.content.ngDynamicComponent.inputs).forEach((input:[string, string]) => {
              compRef.setInput(input[0], input[1]);
            });

            this.changeDetector.detectChanges();
            this.ngComponent.nativeElement.appendChild(compRef.location.nativeElement);
            compRef.changeDetectorRef.detectChanges();

          }
          this.opened = true;
        }
        this.changeDetector.detectChanges();
      });
  }

  public hidePopup(): void {
    this.dyanmicPopupService.popupContent.next(null);
    this.dyanmicPopupService.hidePopupEvent.next(true);
    this.opened = false;
    this.viewContainerRef.clear(); 
  }
}
