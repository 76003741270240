import { OtherDesign } from "../data-models/other-designs/other-designs";

export interface IOtherDesignsApiResponse {
    id: string;
    name: string;
    product_thumbnail: string;
  }

export class OtherDesignsFactory{

    constructor(){}
    
    public createFromApiResponse(apiResponse: IOtherDesignsApiResponse):OtherDesign{
        return new OtherDesign(
          apiResponse.id,
          apiResponse.name,
          apiResponse.product_thumbnail
        );
    }
   
}
