import { Component, OnInit } from "@angular/core";
import { AnimationOptions } from "ngx-lottie";
import { Subscription } from "rxjs";
import { MyModelsService } from "src/app/services/my-models-service";
import { AuthService } from "../../../services/auth.service";

@Component({
  selector: "notifications-count",
  templateUrl: "./notifications-count.component.html",
  styleUrls: ["./notifications-count.component.scss"],
})
export class NotificationsCountComponent implements OnInit {
  lottieIconClock: AnimationOptions = {
    path: "/assets/lottie-files/clock.json",
  };

  private inProgressItemsSubscriptions: Subscription;
  private hasNotificationsDisplayedSubscriptions: Subscription;

  private inProgressItems: number = 0;
  private hasNotificationsDisplayed: number = 0;

  constructor( private myModelsService: MyModelsService, private authService: AuthService) {}

  ngOnInit(): void {
   this.subscribeToLoggedIn();
  }

  subscribeToLoggedIn(): void {
    this.authService.loggedIn.subscribe((isLoggedIn:boolean) => this.onLoginStatusChanged(isLoggedIn));
  }

  onLoginStatusChanged(isLoggedIn:boolean): void {
    if (isLoggedIn) {
      this.subscribeMyModels();
    } else {
      if (this.inProgressItemsSubscriptions && this.hasNotificationsDisplayed) {
        this.unsubscribeMyModels();
        this.inProgressItems = 0;
        this.hasNotificationsDisplayed = 0;          
      }
    }
  }

  subscribeMyModels(): void {
    this.inProgressItemsSubscriptions =
      this.myModelsService.inProgressItems.subscribe((value: any) => {
        this.inProgressItems = value;
      });

    this.hasNotificationsDisplayedSubscriptions =
      this.myModelsService.hasNotificationsDisplayed.subscribe((value: any) => {
        this.hasNotificationsDisplayed = value;
      });
  }

  unsubscribeMyModels(): void {
    this.inProgressItemsSubscriptions.unsubscribe();
    this.hasNotificationsDisplayedSubscriptions.unsubscribe();
  }
}
