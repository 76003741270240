import { Component, Input, Output, EventEmitter, QueryList, ContentChildren } from '@angular/core';
import { TabSwitchComponent } from './tab-switch.component';

@Component({
  selector: 'app-switch-views-tabs',
  templateUrl: './switch-views-tabs.component.html',
  styleUrls: ['./switch-views-tabs.component.scss']
})
export class SwitchViewsTabsComponent {

  @Input() tabsNames: string[];
  @Output() switchTabs: EventEmitter<boolean> = new EventEmitter();
  @ContentChildren(TabSwitchComponent) tabs: QueryList<TabSwitchComponent>;

  public setActiveTab(tabId: string):void {
    this.tabs.forEach((tab:TabSwitchComponent) => tab.active = false);
    this.tabs.find((tab:TabSwitchComponent)=> tab.tabId == tabId).active = true;
  }
  
  constructor(){}

  ngAfterContentChecked(){

    if (this.tabs.length == 0) return;
    
    if (this.tabs.find((tab:TabSwitchComponent)=> tab.tabId == undefined)){
      throw new Error('The "tabId" attribute is missing in the <tabs-switch>.')
    }
    if (this.tabs.filter((tab:TabSwitchComponent)=>tab.active).length == 0){
      this.tabs.first.active = true;
    }

  }
}
