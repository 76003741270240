import { Component } from "@angular/core";
import { Currency, CurrencyService } from "../../services/currencyService";
import { UserService } from "src/app/services/authentication/user.service";

@Component({
  selector: "app-currency-selector",
  templateUrl: "./currency-selector.component.html",
  styleUrls: ["./currency-selector.component.css"],
})
export class CurrencySelectorComponent {
  constructor(
    private currencyService : CurrencyService,
    public userService: UserService
  ) {}

  public currencyItems: Currency;

  get currency(): Currency {
    return this.currencyService.currency;
  }

  get availableCurrencies(): Currency[] {
    return this.currencyService.availableCurrencies;
  }

  public onChangeCurrency(value: Currency): void {
    this.currencyService.setCurrency(value);
  }

  public getCurrencyPlaceholder(currency: Currency): string {
    return CurrencyService.currencyInfoMap.get(currency);
  }
}
