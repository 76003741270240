import {Component, ContentChild, ElementRef, HostListener, Input, OnDestroy, OnInit} from "@angular/core";

@Component({
  selector: "app-form-checkbox-wrapper",
  templateUrl: "./form-checkbox-wrapper.component.html",
  styleUrls: ["./form-checkbox-wrapper.component.scss"]
})
export class FormCheckboxWrapperComponent {
  @ContentChild("checkboxInput") private checkboxInput: ElementRef<HTMLInputElement>;

  @Input() public checked: boolean = true;

  constructor() {}

  @HostListener("change", ["$event"])
  private async onCheckboxChange(): Promise<void> {
    this.checked = this.checkboxInput.nativeElement.checked;      
  }

  public togglePolicyCheckbox(): void {
    this.checkboxInput.nativeElement.click();
    this.checkboxInput.nativeElement.dispatchEvent(new Event("change"));
  }
}