import {PermissionGuardBase} from "./permissionGuardBase.service";
import {IGuardMixin} from "./mixins/IGuardMixin";
import {LoggedGuardMixin} from "./mixins/loggedGuardMixin";
import {UnverifiedGuardMixin} from "./mixins/unverifiedGuardMixin";

export class UnverifiedUserGuard extends PermissionGuardBase {
  protected guardMixins: IGuardMixin[] = [
    new LoggedGuardMixin(this.userService), 
    new UnverifiedGuardMixin(this.userService)
  ];
}
