import { Component, Input, ElementRef, ViewChild, OnInit, Output, EventEmitter } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Db3DApiBackendClient } from "src/app/services/api/db3d-api-backend-client.service";
import { UsersRole } from "src/app/data-models/manage-users/user-role.model";

enum errorMessages {
  status_400 = "At least one user with admin role must be present in the organisation",
  status_404 = "Http failure response",
  status_500 = "Something went wrong on our side. Please try again later. If the problem persists, please get in touch with our support team."
}

@Component({
  selector: "app-user-role-select",
  templateUrl: "./user-role-select.component.html",
  styleUrls: ["./user-role-select.component.scss"]
})

export class UserRoleSelectComponent implements OnInit{

  public environment = environment;
  public errorMessage: string = "";
  private currentRoleSlug: string = "";
  private userRoleBySlug: UsersRole;

  @Input() usersRoleList: Array<UsersRole>;
  @Input() userId: string;
  @Input() defaultUserRoleSlug: string;
  @Input() commitChanges: boolean = true
  @Output() userDetailsEditRole = new EventEmitter<UsersRole>();
  @ViewChild('roleSelect') roleSelect: ElementRef;

  constructor(
    private db3DApiBackendClient: Db3DApiBackendClient
  ) {}

  ngOnInit(): void {
    this.currentRoleSlug = this.defaultUserRoleSlug
  }

  public onRoleChange(value: any){
    if(this.commitChanges)
      this.updateUserRole(value)
    else
    this.userRoleBySlug = this.getUserRoleBySlug(value)
    this.userDetailsEditRole.emit(this.userRoleBySlug)
  }

  public updateUserRole(userRoleSlug: any): any {
    const payload: {"role": UsersRole} = {
      "role": this.getUserRoleBySlug(userRoleSlug),
    };
    this.roleSelect.nativeElement.disabled = true;
    
    return this.db3DApiBackendClient.patchUsersRole(environment.db3dBackendDomain, this.userId, payload).subscribe({
      next: () => {
        this.errorMessage = '';
        this.currentRoleSlug = userRoleSlug;
        this.roleSelect.nativeElement.disabled = false;
      },
      error: (err: HttpErrorResponse) => {
        if(err.status === 400) {
          this.errorMessage = errorMessages.status_400;
        }
        if(err.status === 404) {
          this.errorMessage = errorMessages.status_404;
        }
        if(err.status === 500) {
          this.errorMessage = errorMessages.status_500;
        }
        this.setSelectedRole(this.currentRoleSlug);
        this.roleSelect.nativeElement.disabled = false;
      }
    });
  }

  private getUserRoleBySlug(userRoleSlug: string): UsersRole {
    const choosenRole = this.usersRoleList.find( role => {
      if(role.slug == userRoleSlug) return role;
    });
    this.userDetailsEditRole.emit(choosenRole)
    return choosenRole;
  }

  private setSelectedRole(roleSlug: string): void {
    let optionsArray = this.roleSelect.nativeElement.querySelectorAll('option');
    optionsArray?.find( (option: HTMLOptionElement) => {
      if(option.value === roleSlug) option.selected = true;
    });
  }

  ngOnDestroy(): void {
    this.errorMessage = '';
  }
}
