export type ElementsPrice = {
  currency: string;
  element_sku: string;
  value: number;
}

export type ListPrice = {
  currency: string;
  value: number;
}
export class Price {
  constructor(
    public elementsPrice: Array<ElementsPrice>,
    public listPrice: ListPrice
  ) {}
}
