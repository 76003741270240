import {UserService} from "../../authentication/user.service";
import {ActivatedRouteSnapshot, Router, UrlTree} from "@angular/router";
import {IGuardMixin} from "./IGuardMixin";

export class NotLoggedInGuardMixin implements IGuardMixin {

  constructor(private userService: UserService) {
  }

  getRedirectUrlTree(router: Router, _route: ActivatedRouteSnapshot): UrlTree {
    return router.createUrlTree(["/"]);
  }

  public evaluate(_route: ActivatedRouteSnapshot): boolean {
    return this.userService.getCurrentUser() === null;
  }
}
