import {Component, OnDestroy, OnInit} from "@angular/core";
import { Subscription } from "rxjs";
import { WhiteLabelSetupConfiguration } from "src/app/data-models/wl-account-setup/wl-config-data.model";

import { WhiteLabelConfigurationService } from "src/app/services/white-labels/white-label-configuration.service";

@Component({
  selector: "app-wl-logo",
  templateUrl: "./wl-logo.component.html",
  styleUrls: ["./wl-logo.component.scss"]
})
export class WlLogoComponent implements OnInit, OnDestroy {

  public wlLogoUrl: string = null;
  private whiteLabelSetupConfigurationSubscription: Subscription;

  constructor(
    public whiteLabelConfigurationService: WhiteLabelConfigurationService
  ) {}

  get noLogoWlEndpoint(): string {
    return WhiteLabelConfigurationService.NO_LOGO_URL_FROM_API_MSG;
  }

  ngOnInit(): void {
    this.whiteLabelSetupConfigurationSubscription = this.whiteLabelConfigurationService.whiteLabelSetupConfiguration.subscribe((wlConfig: WhiteLabelSetupConfiguration) => {
      if (wlConfig.logo) this.wlLogoUrl = wlConfig.logo;
    });
  }

  ngOnDestroy(): void {
    this.whiteLabelSetupConfigurationSubscription?.unsubscribe();
  }

}
