import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class LoggingService {

    log(data, logOnProduction = false) {
        if (!environment.production || logOnProduction) {
            //console.log(`%c ${data}`, 'background: #0D6175; color: white');
        }
    }

    alert(data, logOnProduction = false) {
        if (!environment.production || logOnProduction) {
            //console.log(`%c ${data}`, 'background: darkred; color: white; font-size: 18px;');
        }
    }

}
