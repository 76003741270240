import { Component, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-chevron-button',
  templateUrl: './chevron-button.component.html',
  styleUrls: ['./chevron-button.component.scss']
})
export class ChevronButtonComponent {

  @ViewChild('dualStateBtn') dualStateBtn: ElementRef;

  public rotateChevron(): void {
    this.dualStateBtn.nativeElement.classList.toggle('chevron-rotation');
  }
}
