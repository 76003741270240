import { Component, Input } from "@angular/core";
import { UserService } from "src/app/services/authentication/user.service";

@Component({
  selector: "app-price-handler",
  templateUrl: "./price-handler.component.html",
  styleUrls: ["./price-handler.component.scss"]
})
export class PriceHandlerComponent {
  @Input() value: string;
  @Input() currency: string;

  constructor(private userService: UserService ) {}

  get canUserViewPrices(): boolean {
    return this.userService.canUserViewPrices();
  }

}
