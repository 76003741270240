/* eslint-disable arrow-body-style */
import { Injectable } from "@angular/core";
import { IModularConfig } from "src/app/factories/modular-configuration-factory";
import { environment } from "src/environments/environment";

export interface IIntiaroPublicApiClient {
  getDesignConfigForPricing(customConfigurationId: string): Promise<Response>  
  getProductConfigForPricing(pimTenantId: string, productConfigId: string): Promise<Response> 
}

@Injectable({
  providedIn: "root",
})
export class IntiaroPublicApiClient implements IIntiaroPublicApiClient {

  private readonly headers: HeadersInit = new Headers({"Content-Type": "application/json", "Accept": "application/json"});

  constructor() {}

  public async getDesignConfigForPricing(customConfigurationId: string): Promise<Response> {
    return fetch(`${environment.publicApiIntiaro}/user-configuration/uuid/${customConfigurationId}/`,
      { method: "GET", headers: this.headers,}
    );
  }

  public async getProductConfigForPricing(pimTenantId: string, productConfigId: string): Promise<Response> {
    return fetch(`${environment.publicApiIntiaro}/piminstanceapi/${pimTenantId}/product_instance/${productConfigId}/?environment=prod`,
      { method: "GET", headers: this.headers,}
    );
  }
}

export class IntiaroPublicApiClientMock implements IIntiaroPublicApiClient {

  public async getDesignConfigForPricing(_customConfigurationId: string): Promise<Response> {
    const response = {
      "product_configuration":{
        "elements": {
          "0000": {
            "element_id": "0000",
            "variables": {},
            "neighbours": {}
          }
        },
        "product_id": "000"
      }
    };
    
    return new Response(JSON.stringify(response));
  }

  public async getProductConfigForPricing(_pimTenantId: string, _productConfigId: string): Promise<Response> {
    const response = {
      "product_configuration":{
        "elements": {
          "0000": {
            "element_id": "0000",
            "variables": {},
            "neighbours": {}
          }
        },
        "product_id": "000"
      }
    };

    return new Response(JSON.stringify(response));
  }
}