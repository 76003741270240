export interface IModularConfig {
  elements: {};
  product_id: string;
}

export class ModularConfig {
  constructor(
    public elements: {},
    public productId: string,
  ) {}
}

export class ModularConfigFactory {
  static createFromBackendApi(apiResponse: IModularConfig): ModularConfig {
    return new ModularConfig(
      apiResponse.elements,
      apiResponse.product_id
    );
  }
}
