import {UserPermissions, UserService} from "../../authentication/user.service";
import {ActivatedRouteSnapshot, Router, UrlTree} from "@angular/router";
import {IGuardMixin} from "./IGuardMixin";

export class UnverifiedGuardMixin implements IGuardMixin {
  constructor(protected userService: UserService) {}

  getRedirectUrlTree(router: Router, route: ActivatedRouteSnapshot): UrlTree {
    return router.createUrlTree(["/"]);
  }

  public evaluate(_route: ActivatedRouteSnapshot): boolean {
    return !this.userService.getCurrentUser().permissions.includes(UserPermissions.Designer);
  }

}
