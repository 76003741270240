import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root"
})
export class RedirectService {

  static readonly queryParamKey: string = "next";

  constructor(private router: Router) {}

  public getRedirectQueryParams(queryParams: string): string {
    return new URLSearchParams(queryParams).get(RedirectService.queryParamKey);
  }

  public navigateByParam(ref: string): void {
    ref = decodeURIComponent(ref);
    this.router.navigateByUrl(ref);
  }
}
