import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { WhiteLabelContextService } from "./white-labels/white-label-context.service";

export type Colors = ColorSchemeAttribute[];
@Injectable({
  providedIn: "root",
})
export class ColorSchemeService {

  private colorsTemplate: Colors = ColorsLocalStorageController.getLocalstorageColorsTemplate() ?? ColorSchemeAttributesFactory.createDefault();
  public colorsSubject: BehaviorSubject<Colors> = new BehaviorSubject(this.colorsTemplate);

  get apiUrlColorSchemeDetail(): string {
    return `${environment.db3dBackendDomain}/api/wl/${this.whiteLabelContextService.getCurrentWhiteLabelSlug()}/color-scheme/`;
  }

  public getColorAttribute(key: string, colorsTemplate: Colors = this.colorsTemplate): ColorSchemeAttribute {
    return colorsTemplate.find((attribute) => attribute.variable_key === key);
   }

  public updateColorScheme(key: string, value: string): void {

    this.getColorAttribute(key).variable_value = value;

    this.colorsSubject.next(this.colorsTemplate);
    const payload: ColorSchemeAttribute = {
      "variable_key": key,
      "variable_value": value
    };

    const url: string = this.apiUrlColorSchemeDetail + key + "/";

    const colorsTemplate = this.colorsTemplate;
    this.http.patch(url, payload).subscribe({
      next(_value) {
        ColorsLocalStorageController.setLocalstorageColorsTemplate(colorsTemplate)
      },
      error:(_error) => {
        throw new Error();
      }
    });
  }

  public loadColorChemeFromApi(): void {
    this.http.get(this.apiUrlColorSchemeDetail).subscribe({
      next: (response: Array<any>) => {
        if (response.length) {
          this.colorsTemplate = ColorSchemeAttributesFactory.createFromApi(response);
          this.colorsSubject.next(this.colorsTemplate);

          ColorsLocalStorageController.setLocalstorageColorsTemplate(this.colorsTemplate)
        }else{
          setDefaultColors();
        }
      }, error:(_error) => setDefaultColors()
    });
    const setDefaultColors = () => {
      this.colorsTemplate = ColorSchemeAttributesFactory.createDefault();
      this.colorsSubject.next(this.colorsTemplate);
    }

  }

  constructor(private http: HttpClient, private whiteLabelContextService: WhiteLabelContextService) {
    this.colorsSubject.subscribe((colors: Colors) => {
      colors.forEach((attribute) => {
        document.documentElement.style.setProperty("--" + attribute.variable_key, attribute.variable_value);
      });
    });
  }

  public translateKeyValueToColorName(value: string): string {
    return value.toUpperCase().slice(0, 1) + value.split("-").join(" ").slice(1);
  }
}

class ColorsLocalStorageController{
  constructor(){}
  static readonly localStorageKey: string = "db3d-colo-schem-attributes-template";

  static setLocalstorageColorsTemplate(colorsTemplate: Colors): void {
    localStorage.setItem(ColorsLocalStorageController.localStorageKey, JSON.stringify(colorsTemplate));
  }

  static getLocalstorageColorsTemplate(): Colors {
    const colorsTemplate: string = localStorage.getItem(ColorsLocalStorageController.localStorageKey);
    try {
      return JSON.parse(colorsTemplate)
    } catch (_error) {
      return null
    }

  }
}

export class ColorSchemeAttribute {
  constructor(
    public variable_key: string,
    public variable_value: string,
  ) {}
}

class ColorSchemeAttributesFactory {
  constructor() {}

  private static readonly colorsTemplateDefaults: Colors = [
    new ColorSchemeAttribute("main-button-color", "#44adad"),
    new ColorSchemeAttribute("highlight-text-color", "#44adad"),
    new ColorSchemeAttribute("secondary-button-color", "#539BAD"),
    new ColorSchemeAttribute("mobile-menu-background", "#fafafa"),
    // new ColorSchemeAttribute("configurator-color-group", "#0d6175")
  ];

  static createFromApi(response: object[]): Colors {
    const colorsTemplate: Colors = response.map(({variable_value, variable_key}: ColorSchemeAttribute) => new ColorSchemeAttribute(variable_key, variable_value));
    return colorsTemplate;
  }

  static createDefault(): Colors {
    return ColorSchemeAttributesFactory.colorsTemplateDefaults;
  }

}
