
import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgForm, NgModel } from "@angular/forms";
import { Subscription } from "rxjs";
import { ResetPasswordState } from "src/app/data-models/password-reset-state";
import { environment } from "../../../../environments/environment";
import { WhiteLabelContextService } from "src/app/services/white-labels/white-label-context.service";
import { Db3DApiBackendClient } from "src/app/services/api/db3d-api-backend-client.service";
import { PasswordConditionsComponent } from "../sub-components/password-conditions/password-conditions.component";
import { NavigationService } from "src/app/services/NavigationService.service";
import { RegexprPatterns } from "src/app/services/RegexprPatterns";
@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss", "../authentication-module.scss"],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  @ViewChild("passwrodResetRequestForm") passwrodResetRequestForm: NgForm;
  @ViewChild("passwordChangeForm") passwordChangeForm: NgForm;

  @ViewChild(PasswordConditionsComponent) passwordConditionsComponent: PasswordConditionsComponent;

  public formData: {email: string, password1: string, password2: string} = {
    email: "",
    password1: "",
    password2: "",
  };

  public formErrors: any = {};

  public passwordResetFeedbackMessage = "";

  private feedbackMessages = {
    urlInvalid: "The password reset link is invalid. Please try again.",
    generalError: "Something went wrong. Please try again."
  };

  public get enumState(): typeof ResetPasswordState {
    return ResetPasswordState;
  }

  private queryParamsSub = Subscription.EMPTY;
  public passwordResetStage: ResetPasswordState = this.enumState.inputUserForm;
  private resetToken: string = "";
  private resetUser: string = "";

  public passwrodResetRequestFormAttempt: boolean = false;
  public passwordChangeFormAttempt: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private whiteLabelContextService: WhiteLabelContextService,
    private db3DApiBackendClient: Db3DApiBackendClient,
    private router: Router,
    public navigationService: NavigationService
  ) {}

  ngOnInit(): void {
      
    this.queryParamsSub = this.activatedRoute
      .queryParams
      .subscribe((params) => {
        this.resetToken = params.token;
        this.resetUser = params.user_id;
        if (this.resetToken) this.checkIfTokenValid();
        else this.passwordResetStage = this.enumState.inputUserForm;     
      });
  }

  ngOnDestroy(): void {
    this.queryParamsSub.unsubscribe();
  }

  get emailPatternRegexpr(): RegExp {
    return RegexprPatterns.emailRegxExpr;
  }

  get passwordPatternRegexpr(): RegExp {
    return RegexprPatterns.passwordRegxExpr;
  }

  public checkPasswordConditions(event: string): void {
    this.passwordConditionsComponent.checkPasswordConditions(event);
  }

  public goToFirstStep(): void {
    window.location.replace(location.pathname);
  }

  private checkIfTokenValid(): void {

    const payload: { user_id: string, token: string} = {
      user_id: this.resetUser,
      token: this.resetToken
    };

    this.db3DApiBackendClient.checkPasswordResetToken(environment.db3dBackendDomain, payload).subscribe({
      next: (_response) => {
        this.passwordResetStage = this.enumState.newPasswordForm;        
      },
      error: (_error) => {
        this.passwordResetFeedbackMessage = this.feedbackMessages.urlInvalid;
        this.passwordResetStage = this.enumState.feedbackMessage;        
      },
    }
    );
  }

  public goToLogin(): void {
    this.router.navigate([this.navigationService.login]);
  }

  public passwrodResetRequestFormSubmit(): void {
    this.passwrodResetRequestFormAttempt = true;

    if (!this.passwrodResetRequestForm.valid) return;
    
    this.formErrors = {};

    const email: string = this.formData.email.toLowerCase();
    const payload: {email:string, wl_slug: string} = {
      email,
      wl_slug: this.whiteLabelContextService.getCurrentWhiteLabelSlug()
    };

    this.db3DApiBackendClient.passwordReset(environment.db3dBackendDomain, payload).subscribe({
      next: (_successResponse: any) => {
        this.passwordResetStage = this.enumState.resetRequestSent;
      },
      error:(errorResponse: any) => {
        if (errorResponse.error.errors) {
          this.formErrors = errorResponse.error.errors.field_errors || {};
          this.formErrors.non_field_errors = errorResponse.error.errors.non_field_errors || [];
        }

        if (errorResponse.status >= 500 ) {
          this.passwordResetFeedbackMessage = this.feedbackMessages.generalError;
          this.passwordResetStage = this.enumState.feedbackMessage;
        }
      }
    });
  }

  public passwordChangeFormSubmit(): void {
    this.passwordChangeFormAttempt = true;

    if (!this.passwordChangeForm.valid) return;
    
    window.scrollTo(0, 0);
    this.formErrors = {};

    const payload: {password: string, token: string, user_id: string} = {
      password: this.formData.password1,
      token: this.resetToken,
      user_id: this.resetUser
    };

    this.db3DApiBackendClient.passwordResetConfirm(environment.db3dBackendDomain, payload).subscribe({
      next: (_successResponse: any) => {
        this.passwordResetStage = this.enumState.completeAndSuccessful;
      },
      error:(errorResponse:any) => {
        if (errorResponse.error.errors) {
          this.formErrors = errorResponse.error.errors.field_errors || {};
          this.formErrors.non_field_errors = errorResponse.error.errors.non_field_errors || [];
        }

        // Invalid token
        if (errorResponse.status === 403) {
          this.passwordResetFeedbackMessage = this.feedbackMessages.urlInvalid;
          this.passwordResetStage = this.enumState.feedbackMessage;
        } else if (errorResponse.status >= 500) {
          this.passwordResetFeedbackMessage = this.feedbackMessages.generalError;
          this.passwordResetStage = this.enumState.feedbackMessage;
        }
      },
    });    
  }

  public checkInputValidity(inputModel: NgModel, attempt: boolean): boolean {
    return inputModel?.invalid && (inputModel?.dirty || inputModel?.touched || attempt);
  }

}
