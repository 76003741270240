import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: 'paginator.component.html',
  styleUrls: ["./paginator.component.scss"]
})
export class PaginatorComponent {

  @Input() paginatorPage: number;
  @Input() isFirstPage: boolean = false;
  @Input() isLastPage: boolean = false;
  @Output() paginatorEvent = new EventEmitter<number>();

  public getNextPage(): void {
    !this.isLastPage && this.paginatorEvent.emit(this.paginatorPage + 1);
  }

  public getPreviousPage(): void {
    !this.isFirstPage && this.paginatorEvent.emit(this.paginatorPage - 1);
  }
}
