import { UserDetails } from "../data-models/manage-users/user-details.model";
import { UsersRole } from "../data-models/manage-users/user-role.model";

export interface IUserDetailsApiResponse {
  id: number;
  name: string;
  email: string;
  company: string;
  phone_number: string;
  portfolio_url: string;
  status?: string;
  role: UsersRole;
  analytics_attributes: Array<any>;
  date_joined?: string;
  job_position?: string;
  profile_image?: string;
}

export class UsersDetailsFactory {
  public createFromBackendApi(apiResponse: IUserDetailsApiResponse): UserDetails {
    return new UserDetails(
      apiResponse.analytics_attributes,
      apiResponse.id,
      apiResponse.name,
      apiResponse.email,
      apiResponse.company,
      apiResponse.phone_number,
      apiResponse.portfolio_url,
      apiResponse.status,
      apiResponse.role,
      apiResponse.date_joined,
      apiResponse.job_position,
      apiResponse.profile_image
    );
  }
}
