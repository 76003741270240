import { Component } from "@angular/core";
import { OutletsRegistry } from "src/app/services/NavigationService.service";

@Component({
  selector: "app-my-profile",
  templateUrl: "./my-profile.component.html",
  styleUrls: ["./my-profile.component.scss"]
})

export class MyProfileComponent {
  public readonly basicInfoOutlet = OutletsRegistry.basicInfo;
  public readonly profilePictureOutlet = OutletsRegistry.profilePicture;
}
