import { HttpClient } from "@angular/common/http";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { Fake3D } from "src/app/data-models/fake-3D-data.model";
import { GoogleTagManagerHandlerService } from "src/app/services/google-tag-manager.service";
import { Model3DDownloadService } from "src/app/services/model-3d-download.service";
import { PopupService } from "src/app/services/popup.service";
import { GlobalSettingsService } from "src/app/services/settings/global-settings.service";
import { environment } from "../../../environments/environment";
import { MyModelsService } from "../../services/my-models-service";
import { IntiaroAnalyticsClient, IntiaroAnalyticsConstants } from "../../services/analytics/intiaro-analytics.service";

declare var IntiaroEmbed: any;
declare var IntiaroAnalyticsManager: any;

@Component({
  selector: "app-download-model",
  templateUrl: "./download-model.component.html",
  styleUrls: [
    "./download-model.component.css"]
})
export class DownloadModelComponent implements OnInit, OnDestroy {

  public data;
  public availableModelFormats;
  private supportedModelFormats;
  public availableModelQuality;
  public rendering = false;
  private focusInterval;

  private popupTimeout = 30000;

  constructor(
    private popupService: PopupService,
    private http: HttpClient,
    private download3DService: Model3DDownloadService,
    private globalSettingsService: GlobalSettingsService,
    private googleTagManagerHandlerService: GoogleTagManagerHandlerService,
    private dialogRef: MatDialogRef<DownloadModelComponent>,
    private myModelsService: MyModelsService,
    private intiaroAnalyticsClient: IntiaroAnalyticsClient,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.data = data;
  }

  ngOnInit(): void {
    this.initializeData();
  }

  maintainUnityFocus() {
    this.focusInterval = setInterval(() => {
      if (this.rendering) {
        this.focusOnUnity();
      }
    }, 500);
  }

  focusOnUnity() {
    IntiaroEmbed.focus();
  }

  initializeData() {
    this.availableModelFormats = {
      label: "Available model formats",
      key: "model_format",
      type: "singular",
      options: [
        {
          id: "sketchup",
          label: "Sketchup Pro",
          icon: "assets/images/icons/sketchup.png",
          checked: true
        },
        {
          id: "obj",
          label: "Blender (*.obj)",
        },
        {
          id: "collada",
          label: "Collada (*.dae)",
        },
        {
          id: "gltf",
          label: "Gltf",
        },
        {
          id: "obj",
          label: "3DS Max (*.obj)",
        }
      ]
    };

    this.supportedModelFormats = ["collada", "obj", "gltf"];

    this.availableModelQuality = {
      low : {
        size: "s",
        meshReductionFactor: 0.3
      },
      medium : {
        size: "m",
        meshReductionFactor: 0.6
      },
      high : {
        size: "l",
        meshReductionFactor: 1
      }
    };
  }

  download(selectedQuality, event) {
    setTimeout(() => {
      this.closePopup();
    }, this.popupTimeout);
    event.target.classList.add("rendering");
    this.rendering = true;

    const format = this.getSelectedFormat();

    this.intiaroAnalyticsClient.sendEvent(IntiaroAnalyticsConstants.AnalyticsAttrStart3DmodelGenerationEventName, { 
      mesh_format: format, 
      geometry_reduction_factor: selectedQuality.size,
      product_configuration_id: this.data.product.productId,
      id: this.data.product.id,
      product_name: this.data.product.productName,
      name: this.data.product.name
    });

    if (this.data.support360) {
      this.get3dVia360(
        this.data.configuration,
        this.data.product,
        selectedQuality.meshReductionFactor,
        this.data.productId,
        this.data.productVersion,
        this.data.productImageUrl,
        this.data.name
      );
    } else {
      this.maintainUnityFocus();
      const selectedModelormat = {
        size: selectedQuality.size,
        mesh_format: format,
        geometryReductionFactor: selectedQuality.meshReductionFactor,
      };
      if (this.supportedModelFormats.includes(format)) {
        this.get3DModelPicture(selectedModelormat);
      } else {
        this.initiateFake3DDownload(selectedModelormat);
      }
    }
  }

  get3dVia360(
    productConfiguration,
    product,
    geometryReductionFactor,
    productId,
    productVersion,
    productImageUrl,
    productName
  ) {
    const url = `${environment.db3dBackendDomain}/api/3d/generate-model/`;
    const payload = {
      configuration: productConfiguration,
      model_format: this.getSelectedFormat(),
      geometry_reduction_factor: geometryReductionFactor,
      brand_name: this.data.product.brand_name,
      model_id: productId,
      product_version: productVersion,
      product_image_url: productImageUrl,
      product_name: productName
    };
    this.http.post<any>(url, payload).subscribe(
      (response) => {
        this.myModelsService.initialize();
        this.closePopup();
      },
      (error) => {

      }
    );
  }

  getSelectedFormat() {
    let selectedFormat;
    this.availableModelFormats.options.forEach((format) => {
      if (format.checked) selectedFormat = format.id;
    });

    return selectedFormat;
  }

  closePopup() {
    this.dialogRef.close();
  }

  onModelFormatChange(event) {
    this.availableModelFormats = event;
  }

  get3DModelPicture(data) {
    IntiaroEmbed.createPhoto({
      angle: 30,
      callbackFunction: (photo) => {
        data.thumbnail_url = photo.url;
        this.initiate3DModelDownload(data);
      },
      failCallbackFunction: () => {
        this.show3DDownloadFailPopup();
      }
    });
  }

  initiate3DModelDownload(data) {
    const fileName = this.data.name;
    data.filename = fileName;
    data.product = this.data.product;

    IntiaroEmbed.download3DModel({
      meshName: fileName,
      meshFormat: data.mesh_format,
      geometryReductionFactor: data.geometryReductionFactor,
      successCallback: () => { this.onGet3DModelUUIDReadySuccess(); },
      failCallback: () => { this.show3DDownloadFailPopup(); }
    });
  }

  onGet3DModelUUIDReadySuccess() {
    this.closePopup();
  }

  show3DDownloadFailPopup() {
    this.closePopup();
    const options = {
      title: "Requesting 3D model",
      text: [
        "Your request could not be completed. Please try again later.",
      ],
      buttons: [
        {
          text: "Close",
          callback: () => { this.popupService.hidePopup(); }
        }
      ]
    };

    this.popupService.showPopup(options);
  }

  initiateFake3DDownload(data) {
    const fake3DObj = new Fake3D();
    fake3DObj.format = data.mesh_format;
    fake3DObj.size = data.size;
    this.googleTagManagerHandlerService.sendEvent(IntiaroAnalyticsConstants.AnalyticsAttrFake3DModelRequestedEventName, fake3DObj);
    this.intiaroAnalyticsClient.sendEvent(IntiaroAnalyticsConstants.AnalyticsAttrFake3DModelRequestedEventName, { Value: fake3DObj});

    this.getCurrentConfigurationId(
      (id) => { fake3DObj.custom_configuration_uuid = id; this.sendFake3DDownloadRequest(fake3DObj); },
      () => { this.onFake3DDownloadFail(fake3DObj); });
  }

  onFake3DDownloadFail(fake3DObj) {
    const fake3DObjData = fake3DObj;
    fake3DObjData.status = "fail";

    this.googleTagManagerHandlerService.sendEvent(IntiaroAnalyticsConstants.AnalyticsAttrFake3DModelRequestedEventName, fake3DObjData);
    this.intiaroAnalyticsClient.sendEvent(IntiaroAnalyticsConstants.AnalyticsAttrFake3DRequestEventName, { Status: "fail", Value: fake3DObj});
    this.show3DDownloadFailPopup();
  }

  sendFake3DDownloadRequest(fake3DObj) {
    const fake3DUrl = `${this.globalSettingsService.mainDomain}/api/3d-model-request/`;

    const httpBody = fake3DObj;

    this.http.post(fake3DUrl, httpBody).subscribe(
      (response) => {
        const fake3DObjData = fake3DObj;
        fake3DObjData.status = "success";

        this.popupService.hidePopup();
        this.intiaroAnalyticsClient.sendEvent(IntiaroAnalyticsConstants.AnalyticsAttrFake3DRequestEventName, { Status: "success", Value: fake3DObj});
        this.googleTagManagerHandlerService.sendEvent(IntiaroAnalyticsConstants.AnalyticsAttrFake3DModelRequestedEventName, fake3DObjData);
        this.show3DDownloadRequestSentPopup();
      },
      (error) => {
        this.onFake3DDownloadFail(fake3DObj);
      }
    );
  }

  show3DDownloadRequestSentPopup() {
    const options = {
      title: "Requesting 3D model",
      text: [
        "Your request is being processed and you’ll get an e-mail message with the link to the model.",
      ],
      buttons: [
        {
          text: "Close",
          callback: () => { this.popupService.hidePopup(); }
        }
      ]
    };

    this.popupService.showPopup(options);
  }

  getCurrentConfigurationId( successCallback, failCallback ) {
    IntiaroEmbed.saveConfiguration(
      (data) => { successCallback(data.id); },
      () => { failCallback(); }
    );
  }

  ngOnDestroy(): void {
    clearInterval(this.focusInterval);
  }

}

