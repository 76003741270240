/* eslint-disable curly */
import {Component, OnInit, ViewChild} from "@angular/core";
import {AuthService} from "src/app/services/auth.service";

import {RedirectService} from "src/app/services/redirect.service";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import { NgForm, NgModel } from "@angular/forms";
import { NavigationService } from "src/app/services/NavigationService.service";
import { RegexprPatterns } from "src/app/services/RegexprPatterns";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["../authentication-module.scss"],
})
export class LoginComponent implements OnInit {

  @ViewChild("loginForm") loginForm: NgForm;

  public formData: { email: string, password: string, remember: boolean} = {
    email: "",
    password: "",
    remember: true
  };

  private loginAttempt: boolean = false;
  public loginErrorMessage: string = "";

  public nextUrl: string;

  constructor(
    private authService: AuthService,
    private redirectService: RedirectService,
    private router: Router,
    public navigationService: NavigationService,
    public activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.subscribeToQueryParams();
  }

  private subscribeToQueryParams(): void {
    this.activatedRoute.queryParamMap.subscribe((paramMap: ParamMap) => {
      this.nextUrl = paramMap.get(RedirectService.queryParamKey);
    });
  }

  public checkInputValidity(inputModel:NgModel): boolean {
    return inputModel?.invalid && (inputModel?.dirty || inputModel?.touched || this.loginAttempt);
  }

  public onSubmit(): void {
    const email = this.formData.email.toLowerCase();
    this.tryLogin(email, this.formData.password, this.formData.remember);
  }

  get emailPatternRegexpr(): RegExp {
    return RegexprPatterns.emailRegxExpr;
  }

  get passwordPatternRegexpr(): RegExp {
    return RegexprPatterns.passwordRegxExpr;
  }

  public tryLogin(userName: string, password: string, autologin: boolean): void {
    this.loginAttempt = true;
    
    if (!this.loginForm.valid) return;
    
    this.loginErrorMessage = "";

    this.authService.login(userName, password, autologin).subscribe({
      next: (_response) => {
        const nextUrl: string = this.redirectService.getRedirectQueryParams(window.location.search);
        if(nextUrl) {
          this.redirectService.navigateByParam(nextUrl);
        } else {
          this.router.navigate([this.defaultAfterLoginPath]);
        }

        this.loginForm.reset(this.loginForm.value);
      },
      error: (errorResponse) => {
        if (errorResponse.status >= 500)
          this.loginErrorMessage = "Something went wrong. Please try again.";

        if (errorResponse.status === 400) 
          this.loginErrorMessage = "Unable to login with provided credentials";
          
        this.loginForm.reset(this.loginForm.value);
      }
    }
    );
  }

  public get defaultAfterLoginPath(): string {
    return this.navigationService.productList;
  }

}
