import { Component, Input } from "@angular/core";
import { ToastBaseComponent } from "../toast-base.component";

@Component({
  selector: "app-toast-warning",
  templateUrl: "../toast-base.component.html",
  styleUrls: ["../toast-base.component.scss"],
})

export class ToastWarningComponent extends ToastBaseComponent {
  icon: string = "assets/images/icons/warning-icon.svg";
  backgroundColor: string = "#F59638";
  @Input() message: string = "";
}
