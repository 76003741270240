import { Component, OnInit } from "@angular/core";
import { WhiteLabelConfigurationService, ShowPrices} from "src/app/services/white-labels/white-label-configuration.service";

@Component({
  selector: "prices-control",
  templateUrl: "./prices-control.component.html",
  styleUrls: ["./prices-control.component.scss"]
})

export class PricesControlComponent implements OnInit {

  public pricesCheckbox: boolean;
  public whiteLabelSlug: string;
  public errorMsg: string;
  public WlSetupConfig: any;

  constructor(
    private wlConfigService: WhiteLabelConfigurationService
  ) {
  }

  ngOnInit(): void {
    this.wlConfigService.pricesErrorMsg.subscribe( value => {
      this.errorMsg = value;
    });
    this.wlConfigService.whiteLabelSetupConfiguration?.subscribe( value => {
      this.whiteLabelSlug = value.slug;
      this.pricesCheckbox = value.showPrices;
    });
  }

  public updateDisplayingPrices($event: boolean): void {
    const payload: ShowPrices = {"show_prices": $event};
    this.wlConfigService.updateShowPrices(this.whiteLabelSlug, payload);
  }
}
