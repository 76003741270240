import { Component } from "@angular/core";
import { NavigationService } from "src/app/services/NavigationService.service";

@Component({
  selector: "no-models-generated-information-panel",
  templateUrl: "./no-models-generated-information-panel.component.html",
  styleUrls: ["./no-models-generated-information-panel.component.scss"]
})
export class NoModelsGeneratedInformationPanelComponent {

  constructor(
    public navigationService: NavigationService
  ) {}

}
