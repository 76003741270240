import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class UtilitiesService {

  getInitials(fullName: string) {
      const parts = fullName.split(' ');
      let initials = '';

      for (const part of parts) {
        if (part.length > 0) {
          initials += part[0];
        }
      }
      return initials;
  }

  flattenObject(object: any){
    const flatten = (obj, prefix = '', res = {}) => {
      if (obj === null){
        return res[`${prefix}`] = 'null';
      }
      return Object.entries(obj).reduce((r, [key, val]) => {
        const k = `${prefix}${key}`;
        if (typeof val === 'object') {
          flatten(val, `${k}_`, r);
        } else {
          res[k] = val;
        }
        return r;
      }, res);
    };

    return flatten(object);
  }
}
