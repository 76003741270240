import { Injectable } from "@angular/core";

interface IWhiteLabelContextService {
  getCurrentWhiteLabelSlug(): string 
}

@Injectable({
  providedIn: "root"
})
export class WhiteLabelContextService implements IWhiteLabelContextService {

  constructor() {}

  public getCurrentWhiteLabelSlug(): string {
    // use current domain and split it by dots; you'll get array with subdomains
    // if first element in subdomains array is 'www', use second element from array as white label slug
    // if first element in subdomains array is not 'www', use it as white label slug
    const currentDomain = window.location.hostname;
    const subdomainsArray = currentDomain.split(".");
    return (subdomainsArray[0] === "www" ? subdomainsArray[1] : subdomainsArray[0]);
  }
}