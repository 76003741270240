import { HttpClient } from '@angular/common/http';
import { GlobalSettingsService } from './global-settings.service';

declare var setConfig: any;

export function loadSettings(http: HttpClient, settings: GlobalSettingsService): (() => Promise<boolean>) {
  return (): Promise<boolean> => {
    return new Promise<boolean>((resolve: (a: boolean) => void): void => {
        http.get<any>('/assets/settings/global-settings.json').subscribe(
            settingsFile => {

              if (settingsFile.test_build !== undefined) {
                settings.testBuild = settingsFile.test_build;
              }

              if (settingsFile.environment_id !== undefined) {
                settings.environmentId = settingsFile.environment_id;
              }

              if (settingsFile.analytics_url) {
                settings.analyticsUrl = settingsFile.analytics_url;
              }

              if (settingsFile.logout_url) {
                settings.logoutUrl = settingsFile.logout_url;
              }

              if (settingsFile.intiaro_embed_url) {
                settings.intiaroEmbedUrl = settingsFile.intiaro_embed_url;
              }

              if (settingsFile.main_domain) {
                settings.mainDomain = settingsFile.main_domain;
              }

              if (settingsFile.unity_embed_domain) {
                settings.unityEmbedDomain = settingsFile.unity_embed_domain;
              }

              if (settingsFile.login_domain) {
                settings.loginDomain = settingsFile.login_domain;
              }

              if (settingsFile.registration_domain) {
                settings.registrationDomain = settingsFile.registration_domain;
              }

              if (settingsFile.password_reset_domain) {
                settings.passwordResetDomain = settingsFile.password_reset_domain;
              }

              if (settingsFile.country_list_domain) {
                settings.countryListDomain = settingsFile.country_list_domain;
              }

              if (settingsFile.state_list_domain) {
                settings.stateListDomain = settingsFile.state_list_domain;
              }

              if (settingsFile.product_list_domain) {
                settings.productListDomain = settingsFile.product_list_domain;
              }

              if (settingsFile.product_details_domain) {
                settings.productDetailsDomain = settingsFile.product_details_domain;
              }

              if (settingsFile.product_list_filters_domain) {
                settings.productListFiltersDomain = settingsFile.product_list_filters_domain;
              }

              if (settingsFile.save_design_domain) {
                settings.saveDesignDomain = settingsFile.save_design_domain;
              }

              if (settingsFile.unity_build_domain) {
                settings.unityBuildDomain = settingsFile.unity_build_domain;
              }

              if (settingsFile.intiaro_api_domain) {
                settings.intiaroApiDomain = settingsFile.intiaro_api_domain;
              }

              if (settingsFile.model_service_api_domain) {
                settings.modelServiceApiDomain = settingsFile.model_service_api_domain;
              }

              if (settingsFile.request_furniture_ad_url) {
                settings.requestFurnitureAdUrl = settingsFile.request_furniture_ad_url;
              }

              setConfig(settings.intiaroEmbedUrl, settings.mainDomain, settings.intiaroApiDomain);

              resolve(true);
            },
            error => {
                console.log("Could not download settings file.");
                console.log("Using default values.");
                resolve(true);
            }
        );
    });
  };
}
