/* eslint-disable curly */
// How to use popup:
// https://intiaro.atlassian.net/wiki/spaces/IDT/pages/640221185/Generic+popup

import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  AfterViewInit,
  Renderer2,
  ViewChild,
  ElementRef,
  ViewContainerRef,
  ComponentRef
} from "@angular/core";
import { IPopupContent, PopupService } from "src/app/services/popup.service";

@Component({
  selector: "app-popup",
  templateUrl: "./popup.component.html",
  styleUrls: ["./popup.component.scss"]
})
export class PopupComponent implements OnInit {
  @ViewChild("htmlContainer", { static: false }) container: ElementRef;
  @ViewChild("ngComponent", { read: ElementRef }) private ngComponent: ElementRef;

  opened: boolean;
  content: IPopupContent;

  constructor(
    private popupService: PopupService,
    private changeDetector: ChangeDetectorRef,
    private renderer: Renderer2,
    private viewContainerRef: ViewContainerRef
  ) { }

  ngOnInit() {
    this.subscribeToPopupContentService();
  }

  subscribeToPopupContentService() {
    this.popupService.popupContent.subscribe(
      (popupContent: IPopupContent) => {
        if (popupContent) {
          this.content = popupContent;
          this.content.text = this.multiLineTextDecorator(this.content.text);
          this.renderer.setProperty(this.container.nativeElement, "innerHTML", "");
          
          if (this.content.htmlContent) {
            this.renderer.appendChild(this.container.nativeElement, this.content.htmlContent);
          }

          if (this.content.ngDynamicComponent) {
            
            const compRef:ComponentRef<any> = this.viewContainerRef.createComponent(this.content.ngDynamicComponent.component);

            Object.entries(this.content.ngDynamicComponent.inputs).forEach((input:[string, string]) => {
              compRef.setInput(input[0], input[1]);
            });

            this.changeDetector.detectChanges();
            this.ngComponent.nativeElement.appendChild(compRef.location.nativeElement);
            compRef.changeDetectorRef.detectChanges();

          }
          this.opened = true;
        } else {
          this.content = this.popupService.getDefaultContent();
          this.hidePopup();
        }
        this.changeDetector.detectChanges();
      });
  }

  multiLineTextDecorator(text) {
    let textArray = text;
    if (typeof textArray === "string" || textArray instanceof String) {
      textArray = [text];
    }

    return textArray;
  }

  buttonCallback(button) {
    if (button.callback) {
      button.callback();
    }
  }

  hidePopup() {
    this.popupService.hidePopupEvent.next(true);
    this.opened = false;
    this.content.closeButton.callback();
    this.viewContainerRef.clear();
  }
}
