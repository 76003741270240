import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalSettingsService {

  // Used by analytics
  public testBuild = !environment.production;

  public environmentId = environment.environment_id;

  public analyticsUrl = environment.analytics_url;

  // url to be used when logout button is clicked
  public logoutUrl = environment.logout_url;

  // intiaro url for embeding unity player embeding script
  public intiaroEmbedUrl = environment.intiaro_embed_url;

  public mainDomain = environment.main_domain;

  // unity domain for embeding script
  public unityEmbedDomain = environment.unity_embed_domain !== null ? environment.unity_embed_domain : this.mainDomain;

  // authentication domain
  public loginDomain = environment.login_domain !== null ? environment.login_domain : this.mainDomain;

  // registration domain
  public registrationDomain = environment.registration_domain !== null ? environment.registration_domain : this.mainDomain;

  // password reset
  public passwordResetDomain = environment.password_reset_domain !== null ? environment.password_reset_domain : this.mainDomain;

  // countries for designer registration form
  public countryListDomain = environment.country_list_domain !== null ? environment.country_list_domain : this.mainDomain;

  // states for designer registration form
  public stateListDomain = environment.state_list_domain !== null ? environment.state_list_domain : this.mainDomain;

  // product list domain
  public productListDomain = environment.product_list_domain !== null ? environment.product_list_domain : this.mainDomain;

  // product details doman
  public productDetailsDomain = environment.product_details_domain !== null ? environment.product_details_domain : this.mainDomain;

  // product list filters definition
  public productListFiltersDomain = environment.product_list_filters_domain !== null ? environment.product_list_filters_domain : this.mainDomain;

  // domains used for saving my designs
  public saveDesignDomain = environment.save_design_domain !== null ? environment.save_design_domain : this.mainDomain;

  // unity wl location domain
  public unityBuildDomain = environment.unity_build_domain !== null ? environment.unity_build_domain : this.mainDomain;

  // intiaro api domain
  public intiaroApiDomain = environment.intiaro_api_domain !== null ? environment.intiaro_api_domain : this.mainDomain;

  // model service api url
  public modelServiceApiDomain = environment.model_service_api_domain !== null ? environment.model_service_api_domain : this.mainDomain;

  public requestFurnitureAdUrl = environment.request_furniture_ad_url !== null ? environment.request_furniture_ad_url : '';

  public db3dBackendDomain = environment.db3dBackendDomain !== null ? environment.db3dBackendDomain : ''

  constructor() {
  }

}
