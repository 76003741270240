import { Component, Input } from "@angular/core";
import { ProductDetails } from "../../data-models/types";

@Component({
  selector: "app-product-details",
  templateUrl: "./product-details.component.html",
  styleUrls: ["./product-details.component.scss"]
})
export class ProductDetailsComponent {
  name: string | undefined;
  brandName: string | undefined;
  programName: string | undefined;
  categories: string | undefined;

  @Input() productDetails: ProductDetails;

  constructor() {
  }

  ngOnInit(): void {
    this.name = this.productDetails.productName;
    this.brandName = this.productDetails.brandName;
    this.programName = this.productDetails.programName;
    this.categories = this.productDetails.categories;
  }

}
