import { Component } from "@angular/core";
@Component({
  selector: "app-account-setup-wrapper",
  templateUrl: "./account-setup-wrapper.component.html",
  styleUrls: ["./account-setup-wrapper.component.scss"]
})

export class AccountSetupWrapperComponent  {
  constructor() {}
}
