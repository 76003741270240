import { IGuardMixin } from "./mixins/IGuardMixin";
import {PermissionGuardBase} from "./permissionGuardBase.service";
import {LoggedGuardMixin} from "./mixins/loggedGuardMixin";
import {VerifiedGuardMixin} from "./mixins/verifiedGuardMixin";
import {GenericPermissionGuardMixin} from "./mixins/genericPermissionGuardMixin";

/**
 * Requires permissions to enter the route. If the user does not have the required permissions, it will be redirected to the route specified in the redirect property
 * To pass required permissions, you need to pass data: {requiredPermissions: ["permission"]} to the route
 * To pass redirect property, you need to pass data: {redirect: "/your/path"} to the route. If redirect property is not passed, the user will be redirected to the root path
 * redirect property will be overridden if user is not authenticated or is not a designer
 * @class LoggedGuard
 * @classdesc GenericPermissionGuard
 * @export
 */
export class GenericPermissionGuard extends PermissionGuardBase {
  protected guardMixins: IGuardMixin[] = [
    new LoggedGuardMixin(this.userService),
    new VerifiedGuardMixin(this.userService),
    new GenericPermissionGuardMixin(this.userService)
  ];
}
