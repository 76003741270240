import {Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class NavbarService {
  public isVisible: boolean = true;

  constructor() {}

  public hide(): void {
    this.isVisible = false;
    
  }

  public show(): void {
    this.isVisible = true;
  }
}
