import { UsersRole } from "../data-models/manage-users/user-role.model";

export interface IUsersRoleApiResponse {
  name: string;
  slug: string;
  disabled: boolean
}

export class UsersRoleFactory {

  public createFromBackendApi(apiResponse: IUsersRoleApiResponse): UsersRole {
    return new UsersRole(
      apiResponse.name,
      apiResponse.slug,
      apiResponse.disabled
    );
  }
}
