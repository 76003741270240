import { UsersPagination } from "../data-models/manage-users/users-pagination.model";

export interface IUsersPaginationApiResponse {
  page: number;
  next?: string;
  previous?: string;
}

export class UsersPaginationFactory {

  public createFromBackendApi(apiResponse: IUsersPaginationApiResponse): UsersPagination {
    return new UsersPagination(
      apiResponse.page,
      apiResponse.next,
      apiResponse.previous
    );
  }
}
