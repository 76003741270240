import {UserService} from "../../authentication/user.service";
import {ActivatedRouteSnapshot, Router, UrlTree} from "@angular/router";
import {IGuardMixin} from "./IGuardMixin";

export class GenericPermissionGuardMixin implements IGuardMixin {
  constructor(private userService: UserService, private forceRedirect: string = undefined) {
  }

  getRedirectUrlTree(router: Router, route: ActivatedRouteSnapshot): UrlTree {
    if (this.forceRedirect) {
      return router.createUrlTree([this.forceRedirect]);
    }
    if (route.data.redirect) {
      return router.createUrlTree([!route.data.redirect]);
    }
    return router.createUrlTree(["/"]);
  }

  public evaluate(route: ActivatedRouteSnapshot): boolean {
    if (this.userService.getCurrentUser()) {
      const userPermissions = this.userService.getCurrentUser().permissions
      if(!route.data.requiredPermissions || route.data.requiredPermissions.length == 0){
        console.error("GenericPermissionGuard was invoked but requiredPermissions parameter is missing")
        return false
      }
      if (!route.data.requiredPermissions.every((x: string) => userPermissions.includes(x))) {
        return false;
      }
      return true;
    }
    return false;
  }
}
