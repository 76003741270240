import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'truncate' })
export class TruncatePipe implements PipeTransform {
  transform(value: string, limit: number = 20, completeWords = false, ellipsis = '...') {
    if(!value) return;
    if(value.length < limit) {
      return `${value.substring(0, limit)}`;
    }
    if(completeWords) {
      const noSpaceInString = value.lastIndexOf(' ');
      if(noSpaceInString > 0) {
        limit = value.substring(0, limit).lastIndexOf(' ');
      }
    }
    return `${value.substring(0, limit)}${ellipsis}`;
  }
}
