import { Injectable } from "@angular/core";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { UtilitiesService } from "./utilities/utilities.service";

declare var dataLayer: any;

@Injectable({
  providedIn: "root"
})
export class GoogleTagManagerHandlerService {

  constructor(
    private gtmService: GoogleTagManagerService,
    private utilitiesService: UtilitiesService
  ) {}

  public init(): void {
    this.gtmService.addGtmToDom();
  }

  public setDimension(dimensionName: string, dimensionValue: string): void {
    const dataObj = {};
    dataObj[dimensionName] = dimensionValue;
    dataLayer.push(dataObj);
  }

  public sendEvent(eventName: string, eventParams: any = {}): void {
    let eventObj: any = eventParams;
    eventObj = this.utilitiesService.flattenObject(eventObj);
    eventObj.event = eventName;
    dataLayer.push(eventObj);
  }

  public sendUnityEvent(event: any): void {
    let eventObj: any = {};
    if (event.label) eventObj = JSON.parse(event.label);
    eventObj.event = event.category;
    eventObj.action = event.action;
    dataLayer.push(eventObj);
  }
  
}