export class WhiteLabelSetupConfiguration {
  constructor(
    public name: string,
    public slug: string,
    public logo: string,
    public showPrices: boolean,
    public pdfTemplate: string,
    public twoStepRegistration: boolean,
    public loginBackground: string,
    public contactPageVisibile: boolean,
    public domain: string,
    public qrCodeTemplateUrl: string,
    public configuratorInactiveChoicesVisible?: boolean
  ) {}
}
