import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Db3DApiBackendClient } from "src/app/services/api/db3d-api-backend-client.service";
import { environment } from "src/environments/environment";
import { UsersDetailsFactory } from "src/app/factories/user-details-factory";
import { UsersRoleFactory, IUsersRoleApiResponse } from "src/app/factories/user-role-factory";
import { UserDetails } from "src/app/data-models/manage-users/user-details.model";
import { UsersRole } from "src/app/data-models/manage-users/user-role.model";
import { UsersDataFetchMessages } from "src/app/data-models/manage-users/users-data-fetch-messages";
import { HttpErrorResponse } from "@angular/common/http";
import { NgForm, NgModel } from "@angular/forms";
import { RegexprPatterns } from "src/app/services/RegexprPatterns";
import { UpdateUserPayload } from "src/app/data-models/manage-users/user-details-edit-mode.model";
import { ToastService, ToastType } from "src/app/services/toast.service";
import { NavigationService } from "src/app/services/NavigationService.service";
import { Observable, Subject } from "rxjs";
import { PopupService } from "src/app/services/popup.service";
import { WhiteLabelConfigurationService } from "src/app/services/white-labels/white-label-configuration.service";


@Component({
  selector: "app-user-details-edit",
  templateUrl: "./user-details-edit.component.html",
  styleUrls: ["./user-details-edit.component.scss"]
})

export class UserDetailsEditComponent implements OnInit {

  public environment = environment;
  public userId: string;
  public usersRoleList: Array<UsersRole>;
  public userDetailInitialState: UserDetails;
  public userDetailEditState: UserDetails;
  private userDetailsFactory: UsersDetailsFactory = new UsersDetailsFactory();
  private usersRoleFactory: UsersRoleFactory = new UsersRoleFactory();
  public message: string = UsersDataFetchMessages.loading;
  public errorMessage: string;
  public name?: string;
  public company?: string;
  public portfolioUrl: string;
  public phoneNumber?: string;
  public usersRole = new UsersRole("","",false)
  private confirmNavigationSubject: Subject<boolean> = new Subject<boolean>();
  private isRequestProcessing: boolean = false;
  private isCancelProcessing: boolean = false;
  public priorityForUserDecisionOfRedirection: boolean = false;
  public wlSlug: string;

  @ViewChild('editForm') editForm: NgForm;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private db3DApiBackendClient: Db3DApiBackendClient,
    public navigationService: NavigationService,
    public toastService: ToastService,
    private popupService: PopupService,
    private whiteLabelConfigurationService: WhiteLabelConfigurationService,
  ) {}

  ngOnInit(): void {
    this.whiteLabelConfigurationService.whiteLabelSetupConfiguration.subscribe({
      next: (config) => {
        this.wlSlug = config.slug;
      },
    });
    this.userId = this.activatedRoute.snapshot.paramMap.get("id");
    this.setUserDetailsDataEditMode();
    this.getUsersRolesListEditMode();
  }

  public getUserEditRole(editRole: UsersRole) {
    this.userDetailEditState.role = editRole
  }

  public save() {
    if(!this.isInitialDataTheSameAsNewDataInForm() && this.editForm.valid && !this.isRequestProcessing){
    const userEditPayload = this.prepareUserUpdatePayload();
    this.updateUser(userEditPayload);
      if(!this.priorityForUserDecisionOfRedirection) this.backToUserDetail();
    }
  }

  public prepareUserUpdatePayload() {
    const payload: UpdateUserPayload = {
      role: this.usersRole
    };
    const roleData = this.userDetailEditState.role;

    payload.role = {
      name: roleData.name,
      slug: roleData.slug,
    };

    this.isRequestProcessing = true;
    payload.name = this.editForm.value.userName ?? "";
    payload.company = this.editForm.value.company ?? "";
    payload.phone_number = this.editForm.value.phone ?? "";
    payload.portfolio_url = this.editForm.value.portfolio ?? "";
    
    return payload;
  }

  public updateUser(payloadUserRoleDetail) {
    this.db3DApiBackendClient.patchUsersDetailsEditMode(environment.db3dBackendDomain, this.userId, payloadUserRoleDetail).subscribe({
      next: () => {
        this.toastService.showToast("Changes saved!", ToastType.success);
      },
      error: (err: HttpErrorResponse) => {
        this.isRequestProcessing = false;
        if([404, 400, 500].includes(err.status))
          this.toastService.showToast("Changes unsaved!", ToastType.error);
      }
    });
  }

  public checkInputValidity(inputModel:NgModel): boolean {
    return inputModel?.invalid && (inputModel?.dirty || inputModel?.touched);
  }

  get phonePatternRegexpr(): RegExp {
    return RegexprPatterns.phoneNumberRegxExpr;
  }

  public backToUserDetail() {
    this.isCancelProcessing = true;
    this.router.navigate([this.navigationService.adminUsersWithId(parseInt(this.userId))], {queryParams: this.activatedRoute.snapshot.queryParams});
    
  }

  public setUserDetailsDataEditMode() {
    this.db3DApiBackendClient.getUserDetailsData(environment.db3dBackendDomain, this.userId).subscribe({
      next: (value) => {
        this.userDetailInitialState = this.userDetailsFactory.createFromBackendApi(value);
        this.userDetailEditState = this.userDetailsFactory.createFromBackendApi(value);
        this.name = this.userDetailEditState.name;
        this.company = this.userDetailEditState.company;
        this.portfolioUrl = this.userDetailEditState.portfolioUrl;
        this.phoneNumber = this.userDetailEditState.phoneNumber;
      },
      error: (err) => {
        if(err.status === 403)
          this.router.navigate([this.navigationService.pageNotFound]);
        else
          this.message = UsersDataFetchMessages.error;
      }
    });
  }

  public getUsersRolesListEditMode() {
    this.db3DApiBackendClient.getUsersRolesList(environment.db3dBackendDomain, this.wlSlug).subscribe({
      next: (userRolesResponse) => {
        this.usersRoleList = this.buildUsersRoleList(userRolesResponse);
      },
      error: (err) => {
        if(err.status === 403)
          this.router.navigate([this.navigationService.pageNotFound]);
      }
    });
  }

  private buildUsersRoleList(dataFromApi: Array<IUsersRoleApiResponse>): Array<UsersRole> {
    return dataFromApi.map( (usersRoleJson) => {
      return this.usersRoleFactory.createFromBackendApi(usersRoleJson);
    });
  }

  @HostListener("window:beforeunload", ["$event"])
  windowBeforeUnload($event: BeforeUnloadEvent) {
    if(!this.canLeavePage()) $event.returnValue = "Your data will be lost";
  }

  canDeactivate(): Observable<boolean> | boolean {
    const popupOptionsUnsaved = {
      title: "You have unsaved changes",
      text: ["Save changes before leaving"],
      buttons: [
        {
          text: "Save changes",
          callback: () => {
            this.priorityForUserDecisionOfRedirection = true;
            this.save();
            this.confirmNavigationSubject.next(true);
            this.popupService.hidePopup();
          }
        }
      ],
      secondaryButtons: [
        {
          text: "Don't save and leave",
          callback: () => {
            this.confirmNavigationSubject.next(true);
            this.popupService.hidePopup();
          }
        }
      ]
    };
    const popupOptionsInvalid = {
      title: "You have invalid data in the form",
      text: ["Correct and save changes before leaving"],
      buttons: [
        {
          text: "Correct",
          callback: () => {
            this.popupService.hidePopup();
          }
        }
      ],
      secondaryButtons: [
        {
          text: "Leave",
          callback: () => {
            this.confirmNavigationSubject.next(true);
            this.popupService.hidePopup();
          }
        }
      ]
    };
    if(this.canLeavePage()) {
      return true;
    } else {
      this.popupService.showPopup(this.editForm.valid ? popupOptionsUnsaved : popupOptionsInvalid);
      return this.confirmNavigationSubject.asObservable();
    }
  }

  private canLeavePage(): boolean {
    return this.isInitialDataTheSameAsNewDataInForm() || this.isRequestProcessing || this.isCancelProcessing;
  }

  public isInitialDataTheSameAsNewDataInForm(): boolean {
    return (this.company === this.userDetailEditState.company && this.phoneNumber === this.userDetailEditState.phoneNumber &&
            this.name === this.userDetailEditState.name && this.portfolioUrl === this.userDetailEditState.portfolioUrl &&
            this.userDetailInitialState.role.slug === this.userDetailEditState.role.slug);
  }
}
