import { Component, Input } from "@angular/core";
import { ToastBaseComponent } from "../toast-base.component";

@Component({
  selector: "app-toast-success",
  templateUrl: "../toast-base.component.html",
  styleUrls: ["../toast-base.component.scss"],
})

export class ToastSuccessComponent extends ToastBaseComponent {
  icon: string = "assets/images/icons/success-icon.svg";
  backgroundColor: string = "#1D7C4D";
  @Input() message: string = "";
}
