import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GlobalSettingsService } from './settings/global-settings.service';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class NotificationsService {

  private page =0
  private notificationsLimit = 10;
  private notificationsArray = [];
  public notifications = new BehaviorSubject<any>([])  
  public genered = false;
  public pendingJobsInNotifications: boolean;

  private notificationsUpdateInverval;
  private notificationsUpdateFrequecy = 5000;

  constructor(
    private globalSettingsService: GlobalSettingsService,
    private http: HttpClient,
  ) {}

  initialize(callback) {
    this.getAllNotifications(callback);
    this.resetUpdateInterval();
  }

  async getNotificationsPagination(callback = null, page:any) {
    const notificationsUrl = `${environment.db3dBackendDomain}/api/notifications/?items_per_page=${this.notificationsLimit}&page=${page}`;
    if (this.genered) {
      this.genered = false
    }
 

    const response = await this.http.get<any>(notificationsUrl).toPromise();
    callback(response)



  }

  getAllNotifications(callback = null) {
    const notificationsUrl = `${environment.db3dBackendDomain}/api/notifications/?items_per_page=${this.notificationsLimit}`;
    this.http.get<any>(notificationsUrl).subscribe(
        (successResponse) => {


          this.genered = true

          const notificationsJson = successResponse.items;
          if (callback) {
              callback(successResponse);
          }
          this.notificationsArray = notificationsJson;
          this.notifications.next(notificationsJson);

          const hasPendingJobs = successResponse.has_pending_jobs;
          this.pendingJobsInNotifications = hasPendingJobs;
          if (this.pendingJobsInNotifications === false) {
            this.stopUpdateInterval();
          }
        },
        (errorResponse) => {
            // could not get notifications, hopefully they will arrive with next update interval
        }
    );
  }

  resetUpdateInterval() {
    if (this.notificationsUpdateInverval) {
      clearInterval(this.notificationsUpdateInverval);
    }
    this.notificationsUpdateInverval = setInterval(() => {
        this.getAllNotifications();
    }, this.notificationsUpdateFrequecy);
  }

  stopUpdateInterval() {
    if (this.notificationsUpdateInverval) {
      clearInterval(this.notificationsUpdateInverval);
    }
  }

  updateNotifications() {
      this.resetUpdateInterval();
      this.getAllNotifications();
  }

  createUnityDownload3DModelNotification(
    meshFormat,
    productImageUrl,
    productName,
    geometryReductionFactor,
    modelServiceJobId
  ) {
    const save3DModelStatusNotificationUrl = `${environment.db3dBackendDomain}/api/notifications/`;

    const httpBody = {
      model_service_job_id: modelServiceJobId,
      geometry_reduction_factor: geometryReductionFactor,
      product_name: productName,
      product_image_url: productImageUrl,
      mesh_format: meshFormat
    };

    this.http.post(save3DModelStatusNotificationUrl, httpBody).subscribe(
      response => {
        this.updateNotifications();
      },
      error => {
        throw new Error('Download notification failed');
      }
    );
  }

  updateDownload3DModelNotification(data) {

    const id = this.getDownload3DModelNotificationId(data);
    if (id === null) {
      return;
    }

    const save3DModelStatusNotificationUrl = `${this.globalSettingsService.mainDomain}/api/notifications/${id}/`;

    const httpBody = {
      type: `download_3d_model_status`,
      uuid: data.uuid,
      type_specific_data: data.type_specific_data,
      displayed: false
    };

    this.http.patch(save3DModelStatusNotificationUrl, httpBody).subscribe(
      response => {
        this.updateNotifications();
      },
      error => {
        throw new Error('Download notification failed');
      }
    );
  }

  getDownload3DModelNotificationId(data) {

    let id = null;

    this.notificationsArray.forEach(notification => {
      if (notification.type === 'download_3d_model_status') {
          if (notification.uuid === data.uuid) {
            id = notification.id;
          }
        }
    });

    return id;
  }

  updateNotificationsDisplayedFlag(notificationsToUpdate) {
    this.resetUpdateInterval();
    const notificationsDisplayStatusUrl = `${environment.db3dBackendDomain}/api/notifications/set-displayed/`;
    const httpBody = notificationsToUpdate;

    this.http.patch(notificationsDisplayStatusUrl, httpBody).subscribe(
      response => {
        this.getAllNotifications();
      },
      error => {
        throw new Error('Notifications displayed flag change failed');
      }
    );
  }

}
