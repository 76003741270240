/* eslint-disable curly */
/* eslint-disable arrow-body-style */
import { Component, OnInit, ViewChildren, QueryList, ElementRef, ViewChild, ViewContainerRef, ChangeDetectorRef, ComponentRef, OnDestroy, DoCheck } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "../../../environments/environment";
import { ProductDesign } from "../../data-models/product-design";
import { ProductDesignFactory } from "../../factories/product-design-factory";

import { IPopupContent, PopupService } from "src/app/services/popup.service";
import { MyDesignSaverComponent } from "../my-design-saver/my-design-saver.component";
import { ProductDetails } from "../../data-models/types";
import { ProductDesignConfigurator360Component } from "../product-design-configurator360/product-design-configurator360.component";
import { Product360InstancesService } from "src/app/services/product-config-360-instances.service";
import { Subject, Subscription, concatMap, of, skip, tap } from "rxjs";
import { PimConfigurationKeyMapping, PriceCalculatorService } from "src/app/services/price-calculator.service";

import { ModularConfig } from "src/app/factories/modular-configuration-factory";
import { UserService } from "src/app/services/authentication/user.service";
import { ListPrice } from "src/app/data-models/price.model";
import { CurrencyService } from "src/app/services/currencyService";
import { Db3DApiBackendClient } from "src/app/services/api/db3d-api-backend-client.service";
import { NavigationService } from "src/app/services/NavigationService.service";
import { NavbarService } from "src/app/services/navbar.service";

declare var getIntiaroPlayer: any;
declare var clearIntiaroInstances: any;

@Component({
  selector: "app-product-design-edit",
  templateUrl: "./product-design-edit.component.html",
  styleUrls: ["./product-design-edit.component.scss"],
})
export class ProductDesignEditComponent implements OnInit, OnDestroy, DoCheck {
  public productDesignId: string;
  public productDesign: ProductDesign;
  protected readonly environment = environment;
  public configId: any;
  public productType: string;
  public designDetails: ProductDetails | undefined;
  public configurationDetails: Subject<any> = new Subject();
  private customConfigurationId: string;
  public isTabsActive = false;

  public designPrice: number;
  public designPriceCurrency: string;

  private isConfigurator360Loaded: boolean;

  private productDesignFactory: ProductDesignFactory = new ProductDesignFactory();
  public priceDetails: ListPrice = null;
  public isProductOptionsReady: boolean = false;

  public static readonly defaultConfiguratorId = "product-design-configurator";


  @ViewChildren("changeViewTab", { read: ElementRef }) buttons: QueryList<ElementRef>;
  @ViewChild("targetEmbed360Instance", { read: ElementRef }) private targetEmbed360Instance: ElementRef;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private product360InstancesService: Product360InstancesService,
    private priceCalculatorService: PriceCalculatorService,
    private userService: UserService,
    private changeDetectorRef: ChangeDetectorRef,
    private viewContainerRef: ViewContainerRef,
    private currencyService: CurrencyService,
    private db3DApiBackendClient: Db3DApiBackendClient,
    private popupService: PopupService,
    public navigationService: NavigationService,
    private navbarService: NavbarService
  ) {}

  private popupServiceHidePopupEventSubscription: Subscription;

  ngOnInit(): void {
    this.productDesignId = this.activatedRoute.snapshot.paramMap.get("id");
    this.getProductDesignData();
    this.popupServiceHidePopupEventSubscription = this.popupService.hidePopupEvent.subscribe((_) => {
      this.isMyDesignSaverPopupOpend = false;
    });
  }

  ngDoCheck(): void {
    if (document.querySelector(".intiaro360-wrapper")) {
      if (document.body.classList.contains("intiaro360-full-screen") && document.querySelector(".intiaro360-wrapper.full-screen")) {
        this.navbarService.hide();
      } else {
        this.navbarService.show();
      } 
    }
  }

  async getProductDesignData(): Promise<void> {

    this.db3DApiBackendClient.getProductDesign(environment.db3dBackendDomain, this.productDesignId).subscribe({
      next: async(productDesignData) => {
        if(productDesignData !== undefined) {
          this.productDesign = this.productDesignFactory.createFromBackendApi(productDesignData);
          this.setProductDetailsData(
            this.productDesign.productName,
            this.productDesign.brand_name,
            this.productDesign.productVersionName,
            this.productDesign.categories
          );
        }
        this.embed360InstanceComponent();
      },
      error(err) {
        if(err.status === 404) 
          this.router.navigate([this.navigationService.pageNotFound]);
      },
    });
  }

  public isSaveButtonDisabled(): boolean {
    return this.isSectionalBuilderOpen || !this.isConfigurator360Loaded || !this.isProductOptionsReady;
  }

  private instance360ComponentRef: ComponentRef<ProductDesignConfigurator360Component>;


  private isConfigurator360LoadedSubscription: Subscription;
  public embed360InstanceComponent():void {
    this.instance360ComponentRef = this.product360InstancesService.createProductDesignConfigurator360ComponentRef(
      this.productDesign.customConfigurationId,
      this.productDesign.pim_tenant_id,
      this.productDesign.pim_context,
      this.viewContainerRef,
      this.productDesign.saveAndRenderStrategy
    );

    this.changeDetectorRef.detectChanges();
    this.targetEmbed360Instance.nativeElement.appendChild(this.instance360ComponentRef.location.nativeElement);
    this.instance360ComponentRef.changeDetectorRef.detectChanges();


    this.isConfigurator360LoadedSubscription = this.instance360ComponentRef.instance.isConfigurator360Loaded.subscribe((_) => {
      this.addSectionalBuilderEventObserver();
      this.getDesignConfiguration();

      if (this.isPriceEnable()) {
        this.assignCurrencyChange();
      }

      this.addConfigurationChangedListener();
      this.isConfigurator360Loaded = true;
    });
  }
  private assignCurrencyChange(): void {

    const oninitPrice = async() => {
      this.priceDetails = await this.priceCalculatorService.publicCalculate(PimConfigurationKeyMapping.Custom, this.productDesign.customConfigurationId, this.productDesign.pim_tenant_id, this.productDesign.pim_context);
    };

    const onCurrencyChange = async() => {
      this.priceDetails = null;
      const changedConfig = await this.intiaroInstance.getModularConfiguration();
      this.priceDetails = await this.priceCalculatorService.getPriceFromPim(changedConfig, this.productDesign.pim_context, this.productDesign.pim_tenant_id);
    };

    this.currencyService.currencyChange.pipe(
      concatMap((value, index) => index === 0
        ? of(value).pipe(tap(() => oninitPrice()))
        : of(value)
      ), skip(1)).subscribe(() => onCurrencyChange());
  }

  public isSectionalBuilderOpen: boolean = false;
  private addSectionalBuilderEventObserver():void {

    this.intiaroInstance.registerListener(
      "isSectionalBuilderOpen",
      (response:any) => {
        this.isSectionalBuilderOpen = response.isSectionalBuilderOpen;
      });
  }

  private getDesignConfiguration(): void {
    this.intiaroInstance.getProductOptions(true,
      (productOptions: any) => {
        if(this.isConfigurationDetailsVisible()) this.configurationDetails.next(productOptions);
        this.isTabsActive = true;
        if(productOptions?.elements.length > 0) {
          this.isProductOptionsReady = true;
        }
      },
    );
  }

  public isPriceEnable(): boolean {
    return this.userService.canUserViewPrices() && !!this.productDesign.pim_context;
  }

  private hidePrice(): void {
    this.priceDetails = null;
  }

  private addConfigurationChangedListener(): void {

    this.intiaroInstance?.eventEmitter?.on(
      this.intiaroInstance.configurationChangeInitiatedEventName,
      () => this.hidePrice()
    );

    this.intiaroInstance.registerListener("configurationChanged", async(changedConfig: ModularConfig) => {
      this.getDesignConfiguration();
      if (this.isPriceEnable()) {
        this.priceDetails = await this.priceCalculatorService.getPriceFromPim(changedConfig, this.productDesign.pim_context, this.productDesign.pim_tenant_id);
      }
    });
  }

  private get intiaroInstance():any {
    return getIntiaroPlayer(ProductDesignEditComponent.defaultConfiguratorId);
  }

  private isMyDesignSaverPopupOpend: boolean = false;

  
  async saveButtonClicked(): Promise<void> {
    let productPhotoUrl: string;
    const successCallback = (value: string) => {
      productPhotoUrl = value;
    };
    const failCallback = (errorResponse: any) => {
      console.error(errorResponse);
    };

    if (this.intiaroInstance.isSectionalBuilderOpen) return;

    if (this.isMyDesignSaverPopupOpend) return;
    this.isMyDesignSaverPopupOpend = true;

    const configuration = await this.getProductConfiguration();
    const player = this.intiaroInstance;
    await player.getImageUrl(
      false, 
      0, 
      successCallback, 
      failCallback, 
      "none", 
      {resolutionX: 2048, resolutionY: 2048}
    );

    await player.saveConfiguration((id: string) => this.customConfigurationId = id);

    this.showMyDesignSaverPopup(configuration, productPhotoUrl);
  }

  private showMyDesignSaverPopup(configuration: any, productPhotoUrl: string): void {

    const options: IPopupContent = {
      ngDynamicComponent: {
        component: MyDesignSaverComponent,
        inputs:{
          popupService: this.popupService,
          productDesign: this.productDesign,
          customConfigurationId: this.customConfigurationId,
          configuration: configuration,
          productImageUrl: productPhotoUrl,
        }
      }
    };
    setTimeout(() => {
      this.popupService.showPopup(options);
    }, 500);
  }

  get get360ConfiguratorId(): string {
    return "product-design-configurator";
  }

  async getProductConfiguration(): Promise<any> {
    return await getIntiaroPlayer(this.get360ConfiguratorId).getModularConfiguration(
      true,
      (configuration) => configuration,
      (_error) => {
        return null;
      }
    );
  }

  private setProductDetailsData(productName: string, brandName: string, programName: string, categories: string): void {
    this.designDetails = {
      productName: productName,
      brandName: brandName,
      programName: programName,
      categories: categories
    };
  }

  public goToProductDesignView(): void {
    this.router.navigate([this.navigationService.productDesign(this.productDesignId)]);
  }


  ngOnDestroy(): void {
    this.isConfigurator360LoadedSubscription?.unsubscribe();
    this.popupServiceHidePopupEventSubscription?.unsubscribe();
    clearIntiaroInstances();
  }

  public isConfigurationDetailsVisible(): boolean {
    if(this.userService.isLoggedUser()) return this.userService.canViewProductConfigDetails();
  }
}
