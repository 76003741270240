import { Component, OnInit, Input } from "@angular/core";
import { AnimationOptions } from "ngx-lottie";
import { QualityOf3DModels } from "../../../data-models/quality-of-3d-models";
import { GoogleTagManagerHandlerService } from "src/app/services/google-tag-manager.service";
import { IntiaroAnalyticsClient, IntiaroAnalyticsConstants } from "src/app/services/analytics/intiaro-analytics.service";

declare var hj: any;

@Component({
  selector: "my-models-list-item",
  templateUrl: "./my-models-list-item.component.html",
  styleUrls: ["./my-models-list-item.component.scss"],
})
export class MyModelsListItemComponent implements OnInit {
  @Input() model: any;

  private modelQuality;

  spinnerOptions: AnimationOptions = {
    path: "/assets/lottie-files/spinner.json",
  };

  constructor(
    private googleTagManagerHandlerService: GoogleTagManagerHandlerService,
    private intiaroAnalyticsClient: IntiaroAnalyticsClient,
  ) {}

  ngOnInit(): void {
    this.parseQuality();
  }
  parseQuality(): void {
    this.modelQuality = QualityOf3DModels.getQualityNameByReductionFactor(
      this.model.geometry_reduction_factor
    );
  }

  getIconUrlForExtension(extension: string): string {
    return `assets/images/icons/files-extension/${extension}.png`;
  }

  parseDate(date: string): string {
    //Date string generate by iOs is not in a format specified to work with new Date. 
    //The only formats in the spec are a simplified version of ISO-8601 (added in ES5 in 2009 and updated in ES2015 and ES2016)
    const newDate = new Date(date.replace(" ", "T"));
    return `${newDate.getMonth()+1}/${newDate.getDate()}/${newDate.getFullYear()} ${newDate.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" })}`;
  }

  get3DModel(data: any) {
    window.location.href = data.generated_model_url;
    this.sendAnalytics(data);
  }
  sendAnalytics(data: any) {
    const analyticsContext = {
      product_name: data.product_name,
      geometry_reduction_factor: data.geometry_reduction_factor,
      product_configuration_id: data.product_configuration_id,
      mesh_format: data.mesh_format,
      model_service_job_id: data.model_service_job_id,
    };

    this.intiaroAnalyticsClient.sendEvent(
      IntiaroAnalyticsConstants.AnalyticsAttrDownload3DModelStarted, 
      analyticsContext
    );

    this.googleTagManagerHandlerService.sendEvent(
      IntiaroAnalyticsConstants.AnalyticsAttrDownload3DModelStarted,
      data
    );

    hj("tagRecording", ["3d_download"]);
  }
}
