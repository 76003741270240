/* eslint-disable arrow-body-style */
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NavigationService } from "src/app/services/NavigationService.service";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-page-not-found",
  templateUrl: "./page-not-found.component.html",
  styleUrls: ["./page-not-found.component.scss"]
})
export class PageNotFoundComponent implements OnInit {

  public loggedIn: boolean;

  constructor(
    private router: Router,
    private authService: AuthService,
    public navigationService: NavigationService
  ) {}

  ngOnInit(): void {
    this.subscribeToLoggedIn();
  }

  private subscribeToLoggedIn(): void {
    this.authService.loggedIn.subscribe((value) => this.onLoginStatusChanged(value));
  }

  private onLoginStatusChanged(isLoggedIn): void {
    if (isLoggedIn === null) return;
    this.loggedIn = isLoggedIn;
  }

  public goToProductList(): void {
    this.router.navigate([this.navigationService.productList], { queryParams: { type: "standard" }});
  }

  public goToLoginPage(): void {
    this.router.navigate([this.navigationService.login]);
  }
}
