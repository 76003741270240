import { Component, OnInit } from '@angular/core';
import { BreadcrumbsService } from 'src/app/services/breadcrumb.service';
import { IBreadCrumb } from 'src/app/data-models/Ibreadcrumb.interface';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  constructor(
    private breadCrumbsService : BreadcrumbsService
  ) { }

  ngOnInit() {
    this.updateBreadCrumbs();
  }

  updateBreadCrumbs()
  {
    let newBreadCrumbs : Array<IBreadCrumb> = new Array();

    newBreadCrumbs.push({
      label: "About",
      path: "/about",
      active: true
    })

    this.breadCrumbsService.breadcrumbs.next(newBreadCrumbs);
  }

}
